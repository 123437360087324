﻿export const environment = {
    apiUrl: (process.env.NODE_ENV === "development" ? `${window.location.origin}` : "") + process.env.VUE_APP_API_URL,
    apiBot: (process.env.NODE_ENV === "development" ? `${window.location.origin}` : "") + process.env.VUE_APP_API_BOT_URL,
    apiWebhook: (process.env.NODE_ENV === "development" ? `${window.location.origin}` : "") + process.env.VUE_APP_API_WEBHOOK,
    apiOms: (process.env.NODE_ENV === "development" ? `${window.location.origin}` : "") + process.env.VUE_APP_API_OMSHUB_URL,
    apiClient: (process.env.NODE_ENV === "development" ? `${window.location.origin}` : "") + process.env.VUE_APP_API_CLIENT_URL,
    apiMarketplace: (process.env.NODE_ENV === "development" ? `${window.location.origin}` : "") + process.env.VUE_APP_API_MARKETPLACE_URL,
    apiMessenger: (process.env.NODE_ENV === "development" ? `${window.location.origin}` : "") + process.env.VUE_APP_API_MESSENGER_URL,
    apiInstagram: (process.env.NODE_ENV === "development" ? `${window.location.origin}` : "") + process.env.VUE_APP_API_INSTAGRAM_URL,
    apiKeyGPT: (process.env.VUE_APP_API_KEY_IA ? process.env.VUE_APP_API_KEY_IA : ""),
    messageCopilot: (
        process.env.VUE_APP_MESSAGE_COPILOT ? 
        process.env.VUE_APP_MESSAGE_COPILOT : 
        "Você é um copiloto voltado para agentes de atendimento da Caixa Econômica Federal e deve auxiliar respondendo dúvidas sobre procedimentos, processos e informações internas da Caixa Econômica Federal. Perguntas que não estão relacionadas ao escopo da Caixa Econômica Federal devem ser respondidas educadamente, informando que o assunto não é relevante para o uso do copiloto e sugerindo que o usuário faça perguntas sobre os procedimentos e processos corretos. Dado este contexto responda a pergunta abaixo:"
    )
};