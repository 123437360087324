export const route = {
    //ChatbotFlow
    ChatbotFlow: "/ChatbotFlow",
    //Campanha
    GetCampaignsByUser: "/Campanha/v2/GetCampaignsByUser",
    GetCampaignsActive: "/Campanha/v2/GetCampaignsActive",
    //Usuário
    Autenticate: "/Usuario/v2/Autenticate",
    FinishUserSession: "/Usuario/v2/FinishUserSession",
    GetAgentesDisponiveis: "/Usuario/v2/GetAgentesDisponiveis",
    LoadConfigurations: "/Usuario/v2/LoadConfigurations",
    LoadConfigurationsMediaId: "/Usuario/v2/LoadConfigurationsMediaId",
    UpdateStatus: "/Usuario/v2/UpdateStatus",
    GetTokenData: "/Usuario/v2/GetTokenData",
    VerificaToken: "/Usuario/v2/CheckToken",
    ChangePassword: "/Usuario/v2/ChangePassword",
    UpdateSessaoUsuarioDataHoraKeepAlive: "/Usuario/v2/AtualizaSessaoUsuarioDataHoraKeepAlive",
    GetUsuarioById: "/Usuario/v2/GetUsuarioById",
    // OnlineChatQueue
    OnlineChatQueueActive: "/OnlineChatQueue/active",
    OnlineChatQueueActiveAgent: "/OnlineChatQueue/activeAgent",
    OnlineChatQueueActiveAgentAndChannel: "/OnlineChatQueue/activeAgentAndChannel",
    GetChatsByCampaign: "/OnlineChatQueue/ReturnQueue",
    GetChatsByCampaignList: "/OnlineChatQueue/ReturnQueues",
    TransferChat: "/OnlineChatQueue/transferChat",
    TransferChatMotivo: "/OnlineChatQueue/v2/transferChat",
    GetNextChatByCampaign: "/OnlineChatQueue/next",
    FinishItemWithTab: "/OnlineChatQueue/FinishItemWithTab",
    UpdateCustomVars: "/OnlineChatQueue/UpdateCustomVars",
    UpdateForceClose: "/OnlineChatQueue/UpdateForceClose",
    GetChatsNext: "/OnlineChatQueue/nextFromQueueIds",
    FinishAthBackoffice: "/OnlineChatQueue/FinishAthBackoffice",
    GerarAtendimento: "/OnlineChatQueue/new3",
    GetAllChatsFromDataChatIds: "/OnlineChatQueue/GetAllChatsFromDataChatIds",
    GetHsmMensagensSalvasFilter: "/MensagemSalva/v2/ListHSMFilter",
    GetCurrentMonthItems: "/OnlineChatQueue/GetCurrentMonthItems/{empresaId}",
    GetCurrentMonthItemsForChatId: "/OnlineChatQueue/GetCurrentMonthItemsForChatId/{empresaId}/{chatId}",

    //MarketPlace
    UpdateStage: "/v1/claims/{claimId}",
    GetListReclamacoes: "/v1/claims/search?stage=dispute",

    // IndicadoresAtendimento
    InsertIndicadoresLogin: "/IndicadoresAtendimento/InsertIndicadoresLogin?",
    IndicadoresFimAtendimento: "/IndicadoresAtendimento/IndicadoresFimAtendimento",
    IndicadoresLogout: "/IndicadoresAtendimento/IndicadoresLogout",
    nextIndicadores: "/OnlineChatQueue/nextIndicadores",

    // ChatStatus
    UpdateChatStatus: '/ChatStatus/UpdateChatStatus?',
    StatusDate2: '/ChatStatus/StatusDates2?',

    //ChatHistory
    GetHistoryByStartDateByEndDate: "/ChatHistory/{dataInicial}/{dataFinal}?chatId={chatID}&empresaId={empresaId}",
    GetHistoryByChatUUID: "/ChatHistory/ChatUUID",
    GetHistoryByChatIdCompany: "/ChatHistory/GetHistoryN",
    GetLast48hHistory: "/ChatHistory/GetLast48hHistory",
    AddToChatHistory: "/ChatHistory",
    AddFileChat: "/ChatHistory/v2/AddFileChat",
    //IntegracaoMensagem
    SendTelegramMessage: "/IntegracaoMensagem/v2/SendTelegramMessage",
    //Listas
    GetHsmMensagensSalvas: "/MensagemSalva/v2/ListHSM",
    GetListStatus: "/TipoStatus/v2/GetTipoStatusByEmpresaId",
    GetTopicoMensagemRapida: "/MensagemRapida/v2/GetTopicoMensagemRapida",
    GetMensagemRapida: "/MensagemRapida/v2/GetMensagemRapida",
    GetMensagensRapidaByEmpresa: "/MensagemRapida/v2/GetMensagensRapidaByEmpresa",
    //HSM
    AddHsm: "/Hsm/v2/AddHsm",
    //Cliente
    GetCliente: "/Cliente/GetCliente",
    GetClienteByName: "/Cliente/GetClienteByName",
    //Crm Cliente
    GetClienteByChannel: "/CrmCliente/v2/GetClienteByChannel",
    GetClienteByNome: "/CrmCliente/v2/GetClienteByNome",
    GetClienteByCpf: "/CrmCliente/v2/GetClienteByCpf",
    GetClienteById: "/CrmCliente/v2/GetClienteById",
    AddCliente: "/CrmCliente/v2/AddCliente",
    UpdateCliente: "/CrmCliente/v2/UpdateCliente",
    GetClienteByNomeOrCpf: "/CrmCliente/v2/GetClienteByNomeOrCpf",
    //Crm Canal Cliente
    GetCanaisCliente: "/CrmCanalCliente/v2/GetCanaisCliente",
    UpdateCanalCliente: "/CrmCanalCliente/v2/UpdateCanalCliente",
    AddCanalCliente: "/CrmCanalCliente/v2/AddCanalCliente",
    RemoveCanalCliente: "/CrmCanalCliente/v2/RemoveCanalCliente",
    //Crm Endereco Cliente
    GetEnderecosCliente: "/CrmEnderecoCliente/v2/GetEnderecosCliente",
    AddEnderecoCliente: "/CrmEnderecoCliente/v2/AddEnderecoCliente",
    UpdateEnderecoCliente: "/CrmEnderecoCliente/v2/UpdateEnderecoCliente",
    RemoveEnderecoCliente: "/CrmEnderecoCliente/v2/RemoveEnderecoCliente",
    //Atendimento
    SendEmail: "/Atendimento/SendEmail",
    //Tabulacao
    GetTabulacoesByCampanha: "/Tabulacao/v2/GetTabulacoesByCampanha",
    GetTabulacoesByCampanhaAndEmpresa: "/Tabulacao/v2/GetTabulacoesByCampanhaAndEmpresa",
    GetTabulacoesByParent: "/Tabulacao/v2/GetTabulacoesByParent",
    //Numero Enterprise
    GetNumberByCampanhaOrEmpresa: "/NumeroEnterprise/v2/GetNumberByCampanhaOrEmpresa",
    GetNumberByEmpresa: "/Numeroenterprise/v2/GetNumberByEmpresa",
    GetNumberByMediaId: "/Numeroenterprise/GetNumberByMediaId",
    //Bot
    AutenticateBot: "/v1/bots/messageapps",
    GetPedidoAbandono: "/v1/orders",
    GetPedidoFinalizado: "/v1/orders",
    GetClientBot: "/v1/customers/profiles",
    GetClientAdressesBot: "/v1/customers/profiles/addresses",
    GetSessionsFromStore: "/v1/stores/{idStore}/sessions",
    GetStoresByRede: "/v2/chainstores/{id}/availabilities",
    GetStores: "/v1/stores",
    GetPedido: "/v1/orders/pre",
    GetPedido2: "/v2/orders/pre",
    GetProduto: "/v1/stores/{id}/products/{productId}/values",
    GetLoja: "/v1/stores/{id}",
    GetCampanha: "/v1/chainstores/{id}/campaigns",
    GetCartonista: "/v1/customers/integrations/cards",
    GetDadosAdicionaisPedido: "/v1/orders/{id}/addendum",
    GetItemCarrinhoCampanha: "/v1/orders/pre/{id}/campaigns/{idCampaign}",
    GetMetodosEntrega: "/v1/orders/pre/{id}/shipments/simulations",
    PostMetodoEntregaEndereco: "/v1/orders/pre/{id}/shipments/adresses/{idAddress}",
    // GetProductsBySessionAndStore: "/v1/stores/{idStore}/sessions/{idSession}/products",
    GetProductsBySessionAndStore: "/v1/stores/{idStore}/sessions/{idSession}/products/stocks",
    ChangeLoja: "/v1/orders/pre/{id}/stores/{storeId}",
    GetProductsBySessionAndStoreQuery: "/v1/stores/{idStore}/sessions/{idSession}/products/expressions",
    GetProdutDetails: "/v1/stores/{idLoja}/products/{productId}",
    GetPaymentMethods: "/v1/stores/{idLoja}/paymentmethods",
    GetProductsByQuery: "/v2/stores/{id}/products",
    OrderAddendum: "/v1/orders/{id}/addendum",
    PostEnderecoCliente:"/v1/bots/profiles/addresses" ,
    SendWhatsAppBusiness: "/SmallMediumBusiness/Messages",
    //Calendario
    InsertAgenda: "/Scheduler/AgendaCreate",
    GetEventoListByAgent: "/Scheduler/EventListByAgent",
    GetEventoListByEmpresa: "/Scheduler/EventListByEmpresa",
    InsertEvento: "/Scheduler/EventCreate",
    UpdateEvento: "/Scheduler/EventEdit",
    DeleteEvento: "/Scheduler/EventDelete",
    GetAgendaByEmpresa: "/Scheduler/AgendaList",
    GetAgendaByAgent: "/Scheduler/AgendaListByAgent",
    DeleteAgenda: "/Scheduler/AgendaDelete",
    UpdateAgenda: "/Scheduler/AgendaEdit",
    GetAgendaListByService: "/Scheduler/AgendaListByService",
    GetServicoByEmpresa: "/Scheduler/ServiceList",
    GetServiceListByAgenda: "/Scheduler/ServiceListByAgenda",
    GetHorariosDisponiveis: "/Scheduler/EventListByEmptyTimeSlots",
    //Endereco
    GetEstados: "/SmallMediumBusiness/GetListaEstado",
    GetListaCidades: "/SmallMediumBusiness/GetListaCidades",
    ConsultaEndereco: "/SmallMediumBusiness/ConsultaEndereco",
    GetPaises: "/SmallMediumBusiness/GetListaPaises",
    //Tag
    GeTagByEmpresa: "/Tag/List",
    InsertTag: "/Tag/Create",
    GeTagChatList: "/TagChat/List",
    UpdateTagChat: "/OnlineChatQueue/UpdateTags",
    //Empresa
    GetEmpresaByDomain: "/Empresa/v2/GetByDomain",
    //HubOms
    ClientProfile: "/v1/HubOms/ClientProfile",
    GetOrderByUid: "/v1/HubOms/OrderByUid",
    OrdersByClientProperty: "/v1/HubOms/OrdersByClientProperty",
    PluginConfigurationListByCompanyId: "/v1/PluginConfiguration/ListByCompanyId",
    UpdateOrder: "/v1/HubOms/UpdateOrder",
    //Messenger
    SendMessage: "/SendMessage",
    //GPT INTEGRATION
    GPT: "https://api.openai.com/v1/chat/completions"
};
