import axios from "axios";
import { environment } from "../environments/env";
import { route } from "./routeDictionary";

export default {
  async sendMessage(message) {

    const request = {
      model: "gpt-4o-mini-2024-07-18",
      messages: [
        {
          "role": message.role,
          "content": environment.messageCopilot + message.content
        }
      ]
    }

    return axios.post(route.GPT, request, { 
      headers: { Authorization: `Bearer ${environment.apiKeyGPT}`},
      'Content-Type': 'application/json'
    });
  }
}