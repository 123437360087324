<template>
  <div class="login">
    <link
      rel="stylesheet"
      href="/css/skins/dark-skin.min.css"
      v-if="temaEscuro"
    />
    <div v-if="showLogin">
      <div class="authentication-page">
        <div class="position-absolute vw-100 vh-100">
          <div
            class="float-left vh-100 w-100 position-absolute card-bg-logo-left"
            :style="'background-image: url(' + logo + ')'"
            v-if="!temaEscuro"
          ></div>
          <div
            class="float-left vh-100 w-100 position-absolute card-bg-logo-left-dark"
            :style="'background-image: url(' + logo + ')'"
            v-if="temaEscuro"
          ></div>
          <div v-if="false" class="float-left vh-100 w-50 card-bg-left"></div>
          <div v-if="false" class="float-right vh-100 w-50 card-bg-right"></div>
        </div>
        <div
          class="main-layout auth-layout-wrap"
          :style="{ backgroundImage: 'url(' + bgImage + ')' }"
        >
          <div class="position-absolute vw-50"></div>
          <div class="container d-flex flex-column">
            <div
              class="row no-gutters text-center align-items-center justify-content-center min-vh-100"
            >
              <div class="col-12 col-md-6 col-lg-5 col-xl-4 card-bg-1">
                <img
                  src="/img/logo_interage.png"
                  class="col-12"
                  v-if="!temaEscuro"
                />
                <img
                  src="/img/logo_interage_dark.png"
                  class="col-12"
                  v-if="temaEscuro"
                />

                <form class="mb-3 ml-2 mr-2" @submit.prevent="login(false)">
                  <div class="form-group">
                    <label for="username" class="sr-only">Login</label>
                    <input
                      id="username"
                      v-model="usuario"
                      class="form-control form-control-md"
                      :placeholder="tm('msgLogin01')"
                    />
                  </div>
                  <div class="form-group">
                    <label for="password" class="sr-only">{{
                      tm("senha")
                    }}</label>
                    <input
                      id="password"
                      v-model="senha"
                      type="password"
                      class="form-control form-control-md"
                      autocomplete="on"
                      :placeholder="tm('msgLogin02')"
                    />
                  </div>
                  <div class="form-group d-flex justify-content-between">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input chek-remember-me"
                        checked=""
                        id="checkbox-remember"
                      />
                      <label
                        class="custom-control-label text-muted font-size-sm"
                        for="checkbox-remember"
                        >{{ tm("msgLogin03") }}</label
                      >
                    </div>
                    <a class="font-size-sm" href="javascript:void(0)" v-if="isVisible">{{
                      tm("msgLogin04")
                    }}</a>
                  </div>
                  <button
                    class="btn btn-primary btn-lg rounded-pill text-uppercase font-weight-semibold px-5"
                    type="submit"
                  >
                    {{ tm("entrar") }}
                  </button>
                  <h6 id="versao">v1.2.8</h6>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./Login.js"></script>
<style src="./Login.css" scoped></style>
