import MD5 from "crypto-js/md5";
import {
    ref
} from "vue";
import {
    useRouter
} from "vue-router";
import {
    useToast
} from "vue-toastification";
import {
    useStore
} from "vuex";
import UsuarioService from "../../services/UsuarioService";
import EmpresaService from "../../services/UsuarioService";
import ChatService from "../../services/ChatService";
import {
    useI18n
} from 'vue-i18n'

export default {
    props: ['userInfo', 'mediaId', 'tema', 'lang', 'dark', 'email', 'senha','channel'],

    name: "login",
    setup(props) {
        const {
            tm,
            locale
        } = useI18n()
        const router = useRouter();
        const toast = useToast();
        const store = useStore();
        const usuario = ref("");
        const senha = ref("");
        const token = localStorage.getItem("token");
        const usuarioLogado = localStorage.getItem("usuario");
        const temaEscuro = ref((sessionStorage.getItem('temaEscuro') ? JSON.parse(sessionStorage.getItem('temaEscuro')) : false));
        const showLogin = ref(false)
        const logo = ref("");
        const bgImage = ref("");

        //created
        atualizarLogo()

        if (props.email) {
            //console.log('propsemail')
            usuario.value = props.email;
            senha.value = props.senha;
            login(true);
        } else if (props.userInfo) {
            sessionStorage.setItem("isEmbedded", true);
            if (props.tema) {
                sessionStorage.setItem('temaMutant', props.tema);
            } else {
                sessionStorage.setItem('temaMutant', false);
            }
            if (props.dark) {
                sessionStorage.setItem('temaEscuro', props.dark);
            } else {
                sessionStorage.setItem('temaEscuro', false);
            }
            if (props.mediaId) {
                localStorage.setItem("mediaId", props.mediaId);
            }
            if (props.lang) {
                locale.value = props.lang;
                sessionStorage.setItem("lang", props.lang);
            }
            if (props.channel) {
                sessionStorage.setItem("channel", props.channel);
            }
            //console.debug('token recebido', props.userInfo, props.mediaId, props.tema)
            loginByToken(props.userInfo);
        } else {
            sessionStorage.setItem("isEmbedded", false);
            localStorage.setItem("mediaId", "");
            sessionStorage.setItem('temaMutant', false);
            showLogin.value = true
        }
        if (token && usuarioLogado) {
            router.push("Home");
        }

        async function loginByToken(token) {
            localStorage.removeItem("usuario");
            try {
                const response = await UsuarioService.GetTokenData(token);
                let _usuario = response.data.usuario;
                if (_usuario.isAtivo === false) {
                    toast.warning(tm('erro08'));
                } else {
                    if (_usuario.nivel === "AGENTE" || _usuario.nivel === "CONVIDADO") {
                        localStorage.setItem("token", response.data.token);
                        localStorage.setItem("usuario", JSON.stringify(_usuario));
                        store.commit('setUsuario', JSON.parse(localStorage.getItem("usuario")));
                        await store.dispatch("getListStatus");
                        let statusUndefined = _usuario.statusId ? store.state.listStatus.find(x => x.id == _usuario.statusId): undefined;
                        if (statusUndefined == undefined ) {
                          await store.dispatch("verificaStatus", {
                              tm: tm
                          });
                        }
                        router.push("Home");
                        adicionarIndicadorLogin();
                    } else {
                        toast.warning(tm('erro09'));
                    }
                }

            } catch (error) {
                //console.log("loginByTokenError:", error)
                if (error.status === 409) {
                    toast.warning(tm('erro10'));
                    // TODO deslogar usuario automaticamente
                    UsuarioService.FinishUserSession(error.data);
                } else if (error.status === 404) {
                    toast.warning(tm('erro11'));
                }
                localStorage.removeItem("token");
                localStorage.removeItem("usuario");
                store.dispatch('clearStore');
            };
        }

        async function login(fromParameters) {

            var senhaMD5 = "";
            if (fromParameters)
                senhaMD5 = senha.value;
            else
                senhaMD5 = MD5(senha.value).toString();

            if (usuario.value === '' || senha.value === '') {
                toast.error(tm('erro12'));
            } else {
                try {
                    const response = await UsuarioService.Autenticate({
                        usuario: usuario.value,
                        senha: senhaMD5
                    })
                    let _usuario = response.data.usuario;
                    if (_usuario.isAtivo === false) {
                        toast.warning(tm('erro08'));
                    } else {
                        if (_usuario.nivel === "AGENTE" || _usuario.nivel === "CONVIDADO") {
                            localStorage.setItem("token", response.data.token);
                            localStorage.setItem("usuario", JSON.stringify(_usuario));
                            store.commit('setUsuario', JSON.parse(localStorage.getItem("usuario")));
                            await store.dispatch("getListStatus");
                            await store.dispatch("verificaStatus", {
                                tm: tm
                            });
                            router.push("Home");
                            if (_usuario.derrubouSessao) {
                                toast.warning(tm('erro13'));
                            }
                            toast.info(tm('bemvindo'));
                        } else {
                            toast.warning(tm('erro09'));
                        }
                        localStorage.setItem("senhaPadrao", (MD5(senha.value).toString() == "52c69e3a57331081823331c4e69d3f2e"));
                        adicionarIndicadorLogin();
                    }
                } catch (error) {
                    if (error.status === 404) {
                        toast.warning(tm('erro11'));
                    }
                    localStorage.removeItem("token");
                    localStorage.removeItem("usuario");
                    store.dispatch('clearStore');
                };
            }
        }

        async function adicionarIndicadorLogin() {
            await store.dispatch("getListConfiguracoes");
            let conf = store.state.listConfiguracoes.filter(y => y.chave == 'distribuidor.automatico.chamada' && y.empresaId == store.state.usuario.empresaId && y.campanhaId == null)
            if (conf.length != 0 && conf[0].valor.toLowerCase() === 'indicadores') {
                //console.log('login- distribuidor-indicadores')
                await store.dispatch('getMinhasCampanhas');
                var ids = store.state.minhasCampanhas.map(e => e.id).join(',');
                if (ids == undefined || ids.length == 0 || ids == []) {
                    setTimeout(adicionarIndicadorLogin, 1000);
                    console.error("Minhas Campanhas esta Vazio");
                    toast.warning("Minhas Campanhas esta Vazio");
                    return;
                }
                try {
                    await ChatService.InsertIndicadoresLogin(store.state.usuario.id, store.state.usuario.empresaId, ids);

                } catch (e) {
                    console.error(e);
                }
            }
        }


        async function atualizarLogo() {
            // try {
            // const response = await EmpresaService.GetEmpresaByDomain(window.location.origin)
            //  if( response.data.urlImagemPrincipal ) {
            //    logo.value = response.data.urlImagemPrincipal
            //  }
            //  } catch(e) {
            //  } finally {
            //    if (logo.value == "") {
            logo.value = require("@/assets/images/logo-cliqx.png")
            bgImage.value = require("@/assets/images/login_back.png")
                //    }
                //  }
        }

        return {
            props,
            usuario,
            senha,
            login,
            temaEscuro,
            loginByToken,
            showLogin,
            tm,
            logo,
            bgImage
        };
    }
};
