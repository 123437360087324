<template>
  <div v-if="isChatOpen" class="chat__container" :style="isChatOpen ? 'display: block' : 'display: none'">
    <div class="chat__header">
      <span>🤖 Aceleraí IA</span>
      <button class="chat__button--close" @click="closeChat">×</button>
    </div>
    <div class="chat__body" ref="chatBody">
      <div v-for="message in store.state.ath.chatMessages" :key="message.message">

        <div class="chat__bot" v-if="message.role === 'assistant'">
          <div class="chat__message--bot">
            <span>🤖</span>
            <p>
              {{ message.message ? message.message : 'Olá! Como posso te ajudar hoje? 🤖' }}
          </p>
          </div>
        </div>

        <div class="chat__agent" v-if="message.role === 'user' && message.message">
          <div class="chat__message--agent">
            <span>👨🏻‍💻</span>
           <p>
            {{ message.message }}
           </p>
          </div>
        </div>
      </div>

      <div class="chat__agent" v-if="store.state.ath.chatLoader">
        <div class="chat__message--bot">
          <span>🤖</span>
          <div>
            <div class="spinner-grow spinner-grow-sm" role="status"></div>
            <div class="spinner-grow spinner-grow-sm" role="status"></div>
            <div class="spinner-grow spinner-grow-sm" role="status"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="chat__footer">
      <input type="text" placeholder="Me ajuda com..." v-model="user.message" @keyup.enter="addNewMessage" :disabled="store.state.ath.chatLoader"/>
      <button class="chat__button--send" @click="addNewMessage" :disabled="store.state.ath.chatLoader">➤</button> 
    </div>
  </div>
  <div v-else>
      <div class="chat__button--container" @click="openChat">
          <svg class="hw-24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
              />
            </svg>
            <span class="pl-1">
                Precisa de ajuda?
            </span>
      </div>
  </div>
</template>
  
<script setup>
import { ref, onMounted, computed, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";

const isChatOpen = ref(true); 
const chatBody = ref(null);
const store = useStore();
const toast = useToast();

const user = ref({
  role: "user",
  message: ""
})

const messages = ref([
  {
    role: "assistant",
    message: "Olá! Como posso te ajudar hoje?"
  }
])

const scrollToBottom = () => {
  if (chatBody.value) {
    chatBody.value.scrollTop = chatBody.value.scrollHeight;
  }
};

const openChat = async () => {
  isChatOpen.value = true;
  await nextTick();
  scrollToBottom();
}

const closeChat = () => {
  isChatOpen.value = false;
};

onMounted(() => {
  scrollToBottom();
});

const addNewMessage = async() => {
  messages.value.push({
    role: user.value.role,
    message: user.value.message
  })

  store.state.ath.chatMessages.push({
    role: user.value.role,
    message: user.value.message
  })

  store.commit('ath/setGptRequest', messages)

  const sendMessageIA = {
    role: user.value.role,
    content: user.value.message
  }

  if (!sendMessageIA.content) {
    toast.info("É necessário digitar a sua mensagem.")
    return
  }

  user.value.message = ""
  const response = await store.dispatch('ath/integrationGPT', sendMessageIA)

  return response

};

computed(() => {
  return store.state.ath.chatLoader
})

</script>
  
<style scoped>
.chat__container {
  position: fixed;
  bottom: 20px;
  right: 100px;
  width: 370px;
  height: 500px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  font-family: Arial, sans-serif;
}
.chat__button--container {
  display: flex;
  justify-content: center;
  padding: 8px;
  position: fixed;
  bottom: 20px;
  right: 100px;
  width: 180px;
  height: 40px;
  background-color: #ea4c89;
  color: white;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  font-family: Arial, sans-serif;
  cursor: pointer;
}
.chat__header {
  background-color: #EA4C89;
  color: white;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chat__header--span {
  font-weight: bold;
}
.chat__button--close {
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
}
.chat__body {
  padding: 10px;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.chat__bot {
  margin-bottom: 10px;
}
.chat__message--agent {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  white-space: pre-wrap;
}
.chat__message--bot {
  white-space: pre-wrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  width: 320px;
}
.chat__agent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}
.chat__message--bot span {
  font-size: 20px;
}
.chat__message--agent span {
  font-size: 24px;
}
.chat__message--bot p {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 10px;
  margin: 0;
}
.chat__message--agent p {
  background-color: #2F2F2F;
  padding: 10px;
  border-radius: 10px;
  margin: 0;
  color: white;
  max-width: 70%; /* Adiciona essa linha para limitar a largura da mensagem */
}
.chat__footer {
  display: flex;
  padding: 10px;
  border-top: 2px solid #ea4c89;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.chat__footer input {
  flex: 1;
  border: none;
  padding: 10px;
  border-radius: 10px;
  margin-right: 10px;
  outline: none;
}
.chat__button--send {
  background-color: #EA4C89;
  border: none;
  color: white;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}
.chat__button--send:hover {
  background-color: #000;
}

@media screen and (max-width: 1199px) {
  .chat__container {
    position: fixed;
    bottom: 80px;
    right: 100px;
    width: 400px;
    height: 500px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    font-family: Arial, sans-serif;
  }
}
</style>