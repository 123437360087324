<template>
  <div class="main-layout">
    <link rel="stylesheet" href="/css/skins/dark-skin.min.css" v-if="temaEscuro" />
    <link rel="stylesheet" href="/css/skins/ckeditor-dark.min.css" v-if="temaEscuro" />
    <link rel="stylesheet" href="/css/skins/ckeditor-dark.min.css" v-if="temaEscuro" />

    <link
      rel="stylesheet"
      href="/css/skins/cliqx.min.css"
      v-if="store.state.corTema == '#20C9DC'"
    />
    <link
      rel="stylesheet"
      href="/css/skins/mutant.min.css"
      v-if="store.state.corTema == '#000000'"
    />
    <link
      rel="stylesheet"
      href="/css/skins/green.min.css"
      v-if="store.state.corTema == '#4caf50'"
    />
    <link
      rel="stylesheet"
      href="/css/skins/roxo-nubank.min.css"
      v-if="store.state.corTema == '#7d35b5'"
    />
    <link
      rel="stylesheet"
      href="/css/skins/blue.min.css"
      v-if="store.state.corTema == '#3b82f6'"
    />
    <link
      rel="stylesheet"
      href="/css/skins/facebook.min.css"
      v-if="store.state.corTema == 'rgb(55, 101, 201)'"
    />
    <link
      rel="stylesheet"
      href="/css/skins/linkedin.min.css"
      v-if="store.state.corTema == 'rgb(0, 119, 181)'"
    />
    <link
      rel="stylesheet"
      href="/css/skins/twitter.min.css"
      v-if="store.state.corTema == 'rgb(3, 159, 245)'"
    />
    <link
      rel="stylesheet"
      href="/css/skins/indigo.min.css"
      v-if="store.state.corTema == '#6366f1'"
    />
    <link
      rel="stylesheet"
      href="/css/skins/purple.min.css"
      v-if="store.state.corTema == '#a855f7'"
    />
    <link
      rel="stylesheet"
      href="/css/skins/instagram.min.css"
      v-if="store.state.corTema == 'rgb(193, 53, 132)'"
    />
    <link
      rel="stylesheet"
      href="/css/skins/drible.min.css"
      v-if="store.state.corTema == '#ea4c89'"
    />
    <link
      rel="stylesheet"
      href="/css/skins/pink.min.css"
      v-if="store.state.corTema == '#ec4899'"
    />
    <link
      rel="stylesheet"
      href="/css/skins/youtube.min.css"
      v-if="store.state.corTema == '#c4302b'"
    />
    <link
      rel="stylesheet"
      href="/css/skins/red.min.css"
      v-if="store.state.corTema == '#ef4444'"
    />
    <link
      rel="stylesheet"
      href="/css/skins/orange.min.css"
      v-if="store.state.corTema == '#f97316'"
    />
    <link
      rel="stylesheet"
      href="/css/skins/yellow.min.css"
      v-if="store.state.corTema == '#f59e0b'"
    />
    <link
      rel="stylesheet"
      href="/css/skins/sidebar-green.min.css"
      v-if="store.state.corTema == '#10b981'"
    />
    <link
      rel="stylesheet"
      href="/css/skins/teal.min.css"
      v-if="store.state.corTema == '#14b8a6'"
    />

    <!-- Navigation Start -->
    <div class="navigation navbar navbar-light bg-primary" v-if="!isEmbedded">
      <!-- Logo Start -->
      <a class="d-none d-xl-block bg-light rounded p-1">
        <!-- Default :: Inline SVG -->
        <img
          height="45"
          width="45"
          src="./../assets/images/icon-user.png"
          style="border-radius: 50% !important"
          alt=""
        />
        <!-- <svg height="30" width="30" viewBox="0 0 512 511"><g><path d="m120.65625 512.476562c-7.25 0-14.445312-2.023437-20.761719-6.007812-10.929687-6.902344-17.703125-18.734375-18.117187-31.660156l-1.261719-41.390625c-51.90625-46.542969-80.515625-111.890625-80.515625-183.992188 0-68.816406 26.378906-132.101562 74.269531-178.199219 47.390625-45.609374 111.929688-70.726562 181.730469-70.726562s134.339844 25.117188 181.730469 70.726562c47.890625 46.097657 74.269531 109.382813 74.269531 178.199219 0 68.8125-26.378906 132.097657-74.269531 178.195313-47.390625 45.609375-111.929688 70.730468-181.730469 70.730468-25.164062 0-49.789062-3.253906-73.195312-9.667968l-46.464844 20.5c-5.035156 2.207031-10.371094 3.292968-15.683594 3.292968zm135.34375-471.976562c-123.140625 0-216 89.816406-216 208.925781 0 60.667969 23.957031 115.511719 67.457031 154.425781 8.023438 7.226563 12.628907 17.015626 13.015625 27.609376l.003906.125 1.234376 40.332031 45.300781-19.988281c8.15625-3.589844 17.355469-4.28125 25.921875-1.945313 20.132812 5.554687 41.332031 8.363281 63.066406 8.363281 123.140625 0 216-89.816406 216-208.921875 0-119.109375-92.859375-208.925781-216-208.925781zm-125.863281 290.628906 74.746093-57.628906c5.050782-3.789062 12.003907-3.839844 17.101563-.046875l55.308594 42.992187c16.578125 12.371094 40.304687 8.007813 51.355469-9.433593l69.519531-110.242188c6.714843-10.523437-6.335938-22.417969-16.292969-14.882812l-74.710938 56.613281c-5.050781 3.792969-12.003906 3.839844-17.101562.046875l-55.308594-41.988281c-16.578125-12.371094-40.304687-8.011719-51.355468 9.429687l-69.554688 110.253907c-6.714844 10.523437 6.335938 22.421874 16.292969 14.886718zm0 0" data-original="#000000" class="active-path" data-old_color="#000000" fill="#665dfe" /></g> </svg> -->
        <!-- Alternate :: External File link -->
        <!-- <img class="injectable" src="./../assets/media/logo.svg" alt=""> -->
      </a>
      <!-- Logo End -->
      <!-- Main Nav Start -->
      <ul
        class="nav nav-minimal flex-row flex-grow-1 justify-content-center flex-xl-column justify-content-xl-center"
        id="mainNavTab"
        role="tablist"
      >
        <!-- Chats Tab Start -->
        <li class="nav-item">
          <a
            :class="
              store.state.navTab === mutationsTypes.CHATS
                ? 'nav-link px-3 active'
                : 'nav-link px-3'
            "
            id="chats-tab"
            href="#chats-content"
            @click="setChats"
            title="Chats"
          >
            <!-- Default :: Inline SVG -->
            <svg class="hw-24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
              />
            </svg>

            <!-- Alternate :: External File link -->
            <!-- <img class="injectable hw-24" src="./../assets/media/heroicons/outline/chat-alt-2.svg" alt="Chat icon"> -->
          </a>
        </li>
        <!-- Chats Tab End -->
        <!-- Profile Tab Start -->
        <li class="nav-item">
          <a
            :class="
              store.state.navTab === mutationsTypes.PROFILE
                ? 'nav-link px-3 active'
                : 'nav-link px-3'
            "
            id="profile-tab"
            href="#profile-content"
            @click="setProfile"
            title="Profile"
          >
            <!-- Default :: Inline SVG -->
            <svg class="hw-24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <!-- Alternate :: External File link -->
            <!-- <img class="injectable hw-24" src="./../assets/media/heroicons/outline/user-circle.svg" alt="Profile icon"> -->
          </a>
        </li>
        <!-- Profile Tab End -->
      </ul>
      <!-- Main Nav End -->
    </div>
    <!-- Navigation End -->
    <!-- Sidebar Start -->
    <aside class="sidebar">
      <ChatGPT />
      <!-- Tab Content Start -->
      <div class="tab-content">
        <!-- Chat Tab Content Start -->
        <div
          class="tab-pane active"
          v-if="store.state.navTab === mutationsTypes.CHATS"
          id="chats-content"
        >
          <div class="d-flex flex-column h-100">
            <div class="hide-scrollbar h-100" id="chatContactsList">
              <!-- Chat Header Start -->
              <div class="sidebar-header sticky-top p-2">
                <div class="d-flex justify-content-between align-items-center">
                  <!-- Chat Tab Pane Title Start -->
                  <button
                    :class="getClassToBackButton()"
                    type="button"
                    data-close=""
                    v-show="store.state.mainTab"
                  >
                    <!-- Default :: Inline SVG -->
                    <svg
                      @click="setMainTab(false)"
                      class="hw-20"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M10 19l-7-7m0 0l7-7m-7 7h18"
                      />
                    </svg>

                    <!-- Alternate :: External File link -->
                    <!-- <img class="injectable hw-20" src="./../assets/media/heroicons/outline/arrow-left.svg" alt=""> -->
                  </button>
                  <h5 class="font-weight-semibold mb-0">{{ nomeUsuario }}</h5>
                  <!-- Chat Tab Pane Title End -->
                  <b class="mx-auto" v-if="exibirBtnPuxarAtend">
                    {{ tm("fila") }}
                    <i class="fila-dot pulsered d-inline-block" v-if="temFila"></i>
                    <i class="fila-dot green-dot d-inline-block" v-if="!temFila"></i>
                  </b>
                  <div>
                    <a
                      :title="tm('puxarAtendimentoAleatorio')"
                      class="mr-2"
                      href="javascript::void"
                      role="button"
                      data-toggle="modal"
                      data-target="#puxarAtendimento"
                      @click="campanhaAtendimento()"
                      v-if="temFila && exibirBtnPuxarAtend"
                    >
                      <svg
                        class="hw-20 rounded-circle"
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        width="24"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"
                        />
                      </svg>
                    </a>
                    <a
                      v-if="isHsmAvailable"
                      :title="tm('enviarHsm')"
                      class=""
                      href="#"
                      role="button"
                      data-toggle="modal"
                      data-target="#envarHsm"
                      @click="clearHsm"
                    >
                      <svg
                        class="hw-20 mr-2"
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        width="24"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                        />
                      </svg>
                    </a>
                    <a
                      :title="tm('gerarAtendimento')"
                      class=""
                      href="#"
                      role="button"
                      data-toggle="modal"
                      data-target="#novoAtendimento"
                      @click="setNovoAtendimento"
                      v-if="isEmbedded && exibirBtnGerarAtendimento"
                    >
                      <svg
                        class="hw-20 mr-2"
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        width="24"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                        />
                      </svg>
                    </a>
                    <a
                      title="Logout"
                      class=""
                      href="#"
                      role="button"
                      data-toggle="modal"
                      data-target="#logout"
                      v-if="!isEmbedded"
                    >
                      <svg
                        class="hw-20 mr-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                        />
                      </svg>
                    </a>
                  </div>
                </div>

                <!-- Sidebar Header Start -->
                <div class="sidebar-sub-header">
                  <!-- Sidebar Header Dropdown Start -->
                  <div class="input-group">
                    <select
                      class="custom-select font-size-sm shadow-none"
                      v-model="statusSelected"
                    >
                      <option
                        v-for="status in listStatus"
                        :key="status.id"
                        :value="status.id"
                      >
                        {{ status.nome }}
                      </option>
                    </select>
                  </div>
                  <!-- Sidebar Search Start -->
                  <!-- Drop down minhas campanhas -->
                  <div class="input-group">
                    <div class="dropdown col-12 px-1">
                      <button
                        class="btn btn-outline-default dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {{ tm("minhasCampanhas") }}
                      </button>
                      <form
                        class="dropdown-menu w-auto mx-1"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div
                          v-for="campanha in store.state.minhasCampanhas"
                          :key="campanha.id"
                        >
                          <label
                            class="dropdown-item px-2"
                            :title="campanha.fila + ' ' + tm('clientesFila')"
                          >
                            <div
                              class="custom-control custom-switch"
                              v-if="stateLocal.operadorDesmarcarCampanha == true"
                            >
                              <input
                                :id="'chekcmp' + campanha.id"
                                type="checkbox"
                                v-model="campanhasFilter"
                                class="custom-control-input"
                                :value="campanha"
                              />
                              <label
                                class="custom-control-label mb-0"
                                :for="'chekcmp' + campanha.id"
                              ></label>
                            </div>
                            {{ campanha.nome }} -<b class="ml-1">{{ campanha.fila }}</b>
                            <a
                              class="ml-1"
                              href="javascript::void"
                              role="button"
                              data-toggle="modal"
                              data-target="#puxarAtendimento"
                              :title="tm('puxarAtendimento') + ' ' + campanha.nome"
                              @click="campanhaAtendimento(campanha)"
                              v-if="exibirBtnPuxarAtend && campanha.fila > 0"
                            >
                              <i class="fas fa-download"></i>
                            </a>
                          </label>
                        </div>
                      </form>
                    </div>
                  </div>

                  <!-- Sidebar Search End -->
                </div>
                <div class="sidebar-sub-header" v-if="false">
                  <div class="input-group">
                    <div class="dropdown">
                      <button
                        class="custom-select font-size-sm shadow-none"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {{ tm("Motivos") }}
                      </button>
                      <form
                        class="dropdown-menu w-auto mx-1"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <label class="dropdown-item px-2" :title="TODOS">
                          <a
                            class="contacts-link"
                            href="#"
                            @click="setMotivosFilter('TODOS')"
                          >
                            {{ "TODOS" }} <b class="ml-1"></b>
                          </a>
                        </label>
                        <div
                          v-for="(categoriaMotivo, index) in listCategorizada"
                          :key="index"
                        >
                          <label class="dropdown-item px-2" :title="categoriaMotivo">
                            <a
                              class="contacts-link"
                              href="#"
                              @click="setMotivosFilter(categoriaMotivo)"
                            >
                              {{ categoriaMotivo }} <b class="ml-1"></b>
                            </a>
                          </label>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="input-group">
                    <input
                      type="search"
                      class="form-control col-12"
                      :placeholder="tm('nPedido')"
                      aria-label="Search"
                      aria-describedby="busca-addon"
                      v-model="stateLocal.numero_pedido"
                      :disabled="stateLocal.isLoading"
                    />&nbsp;
                  </div>
                </div>

                <!-- Sidebar Header End -->
              </div>
              <!-- Chat Header End -->
              <!-- Chat Contact List Start -->

              <!-- Chat input Search Start-->
              <ul
                v-if="!contactListCategorizada"
                class="contacts-list unread"
                id="chatContactTab"
                data-chat-list=""
              >
                <!-- Chat Item Start -->
                <div v-for="chats in currentChatQueue" :key="chats.uuid">
                  <li
                    :class="
                      !selectedChatQueue
                        ? 'contacts-item unread'
                        : chats.chatId != selectedChatQueue.chatId ||
                          chats.uuid != selectedChatQueue.uuid
                        ? 'contacts-item unread'
                        : 'contacts-item unread active'
                    "
                  >
                    <a class="contacts-link" href="#" @click="setChatBody(chats)">
                      <div
                        :class="
                          isNewMessage(chats.chatId) ? 'avatar avatar-online' : 'avatar'
                        "
                      >
                        <img
                          :src="require(`@/assets/images/${chats.channel}.png`)"
                          alt=""
                        />
                      </div>
                      <div class="contacts-content">
                        <div class="contacts-info">
                          <h5 class="chat-name">
                            {{
                              chats.contactName
                                ? chats.contactName + " (" + chats.chatId + ")"
                                : chats.chatId
                            }}
                                                      </h5>
                          <div
                            v-if="chats.channel == constants.TipoCanal.indexOf('EMAIL')"
                            class="dateTime"
                          >
                            {{
                              moment(chats.email.data)
                                .tz(timezoneDefault)
                                .format("DD/MM/YYYY")
                            }}
                          </div>
                          <div v-else-if="chats.dateAdded" class="dateTime">
                            {{
                              moment(chats.dateAdded)
                                .tz(timezoneDefault)
                                .format("DD/MM/YYYY")
                            }}
                          </div>
                        </div>
                        <div class="contacts-texts">
                          <p class="text-truncate">
                            {{
                              chats.flowName
                                ? chats.queueId + " - " + chats.flowName
                                : chats.queueId
                            }}
                          </p>
                          <span
                            v-if="isMarketplace(chats.channel)"
                            class="ml-1 badge badge-danger"
                          >
                            {{ getSla(chats) }}
                          </span>
                          <div class="ml-1 badge badge-rounded badge-danger">
                            {{ qtdNewMsgTela(chats.chatId) }}
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                </div>
              </ul>
              <div v-else id="agrupadorAbandono">
                <ul class="contacts-list unread" id="chatContactTab" data-chat-list="">
                  <!-- Chat Item Start -->
                  <div
                    class="blocoMotivacao"
                    v-for="(categoria, index) in listCategorizada"
                    :key="index"
                  >
                    <h6
                      data-toggle="collapse"
                      @click="expandirColapsarAgrupamento"
                      href="#itensSemInteracao"
                      aria-controls="itensSemInteracao"
                      aria-expanded="true"
                      style="cursor: pointer"
                    >
                      <i class="fas fa-chevron-down fa-fw"></i>
                      {{
                        categoria +
                        " (" +
                        currentChatQueueAgrupado(categoria).length +
                        ")"
                      }}
                    </h6>
                    <div id="itensSemInteracao" class="show">
                      <div
                        v-for="chats in currentChatQueueAgrupado(categoria)"
                        :key="chats.uuid"
                      >
                        <li
                          :class="
                            !selectedChatQueue
                              ? 'contacts-item unread'
                              : chats.chatId != selectedChatQueue.chatId ||
                                chats.uuid != selectedChatQueue.uuid
                              ? 'contacts-item unread'
                              : 'contacts-item unread active'
                          "
                        >
                          <a class="contacts-link" href="#" @click="setChatBody(chats)">
                            <div
                              :class="
                                isNewMessage(chats.chatId)
                                  ? 'avatar avatar-online'
                                  : 'avatar'
                              "
                            >
                              <img
                                :src="require(`@/assets/images/${chats.channel}.png`)"
                                alt=""
                              />
                            </div>
                            <div class="contacts-content">
                              <div class="contacts-info">
                                <h5 class="chat-name">
                                  {{
                                    chats.contactName
                                      ? chats.contactName + " (" + chats.chatId + ")"
                                      : chats.chatId
                                  }}
                                </h5>
                                <div
                                  v-if="
                                    chats.channel == constants.TipoCanal.indexOf('EMAIL')
                                  "
                                  class="dateTime"
                                >
                                  {{
                                    moment(chats.email.data)
                                      .tz(timezoneDefault)
                                      .format("DD/MM/YYYY")
                                  }}
                                </div>
                                <div v-else-if="chats.dateAdded" class="dateTime">
                                  {{
                                    moment(chats.dateAdded)
                                      .tz(timezoneDefault)
                                      .format("DD/MM/YYYY")
                                  }}
                                </div>
                              </div>
                              <div class="contacts-texts">
                                <p class="text-truncate">
                                  {{
                                    chats.flowName
                                      ? chats.queueId + " - " + chats.flowName
                                      : chats.queueId
                                  }}
                                </p>
                                <span
                                  v-if="isMarketplace(chats.channel)"
                                  class="ml-1 badge badge-danger"
                                >
                                  {{ getSla(chats) }}
                                </span>
                                <div class="ml-1 badge badge-rounded badge-danger">
                                  {{ qtdNewMsgTela(chats.chatId) }}
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                      </div>
                    </div>
                  </div>
                  <div class="blocoMotivacao">
                    <h6
                      data-toggle="collapse"
                      @click="expandirColapsarAgrupamento"
                      href="#itensDemaisSituacoes"
                      aria-controls="itensDemaisSituacoes"
                      aria-expanded="true"
                      style="cursor: pointer"
                    >
                      <i class="fas fa-chevron-down fa-fw"></i>
                      {{
                        tm("demaisSituacoes") +
                        " (" +
                        currentChatQueueSemMotivacao.length +
                        ")"
                      }}
                    </h6>
                    <div id="itensDemaisSituacoes" class="show">
                      <div
                        v-for="chats in currentChatQueueSemMotivacao"
                        :key="chats.uuid"
                      >
                        <li
                          :class="
                            !selectedChatQueue
                              ? 'contacts-item unread'
                              : chats.chatId != selectedChatQueue.chatId ||
                                chats.uuid != selectedChatQueue.uuid
                              ? 'contacts-item unread'
                              : 'contacts-item unread active'
                          "
                        >
                          <a class="contacts-link" href="#" @click="setChatBody(chats)">
                            <div
                              :class="
                                isNewMessage(chats.chatId)
                                  ? 'avatar avatar-online'
                                  : 'avatar'
                              "
                            >
                              <img
                                :src="require(`@/assets/images/${chats.channel}.png`)"
                                alt=""
                              />
                            </div>
                            <div class="contacts-content">
                              <div class="contacts-info">
                                <h5 class="chat-name">
                                  {{
                                    chats.contactName
                                      ? chats.contactName + " (" + chats.chatId + ")"
                                      : chats.chatId
                                  }}
                                </h5>
                                <div
                                  v-if="
                                    chats.channel == constants.TipoCanal.indexOf('EMAIL')
                                  "
                                  class="dateTime"
                                >
                                  {{
                                    moment(chats.email.data)
                                      .tz(timezoneDefault)
                                      .format("DD/MM/YYYY")
                                  }}
                                </div>
                                <div v-else-if="chats.dateAdded" class="dateTime">
                                  {{
                                    moment(chats.dateAdded)
                                      .tz(timezoneDefault)
                                      .format("DD/MM/YYYY")
                                  }}
                                </div>
                              </div>
                              <div class="contacts-texts">
                                <p class="text-truncate">
                                  {{
                                    chats.flowName
                                      ? chats.queueId + " - " + chats.flowName
                                      : chats.queueId
                                  }}
                                </p>
                                <span
                                  v-if="isMarketplace(chats.channel)"
                                  class="ml-1 badge badge-danger"
                                >
                                  {{ getSla(chats) }}
                                </span>
                                <div class="ml-1 badge badge-rounded badge-danger">
                                  {{ qtdNewMsgTela(chats.chatId) }}
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>
              <!-- Chat Contact List End -->
            </div>
          </div>
        </div>
        <!-- Chats Tab Content End -->
        <!-- Profile Tab Content Start -->
        <div
          class="tab-pane active"
          v-if="store.state.navTab === mutationsTypes.PROFILE"
          id="profile-content"
        >
          <div class="d-flex flex-column h-100">
            <div class="hide-scrollbar">
              <!-- Sidebar Header Start -->
              <div class="sidebar-header sticky-top p-2 mb-3">
                <h5 class="font-weight-semibold">{{ tm("perfil") }}</h5>
                <p class="text-muted mb-0">{{ tm("informacoesPessoais") }}</p>
              </div>
              <!-- Sidebar Header end -->
              <!-- Sidebar Content Start -->
              <div class="container-xl">
                <div class="row">
                  <div class="col">
                    <!-- Card Start -->
                    <div class="card card-body card-bg-5">
                      <!-- Card Details Start -->
                      <div class="d-flex flex-column align-items-center">
                        <div class="avatar avatar-lg mb-3">
                          <img
                            class="avatar-img"
                            src="./../assets/images/icon-user.png"
                            alt=""
                          />
                        </div>

                        <div class="d-flex flex-column align-items-center">
                          <h5>{{ nomeUsuario }}</h5>
                        </div>

                        <div class="d-flex">
                          <button
                            class="btn btn-outline-default mx-1"
                            type="button"
                            data-toggle="modal"
                            data-target="#logout"
                          >
                            <!-- Default :: Inline SVG -->
                            <svg
                              class="hw-18 d-none d-sm-inline-block"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                              />
                            </svg>

                            <!-- Alternate :: External File link -->
                            <!-- <img class="injectable hw-18" src="./../assets/media/heroicons/outline/logout.svg" alt=""> -->
                            <span>Logout</span>
                          </button>
                          <button
                            @click="setMainTab(true)"
                            class="btn btn-outline-default mx-1 d-xl-none"
                            data-profile-edit=""
                            type="button"
                          >
                            <!-- Default :: Inline SVG -->
                            <svg
                              class="hw-18 d-none d-sm-inline-block"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                              />
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                            </svg>

                            <!-- Alternate :: External File link -->
                            <!-- <img class="injectable hw-18" src="./../assets/media/heroicons/outline/cog.svg" alt=""> -->
                            <span>{{ tm("configuracoes") }}</span>
                          </button>
                        </div>
                      </div>
                      <!-- Card Details End -->
                    </div>
                    <!-- Card End -->
                    <!-- Card Start -->
                    <div class="card mt-3">
                      <!-- List Group Start -->
                      <ul class="list-group list-group-flush">
                        <!-- List Group Item Start -->
                        <li class="list-group-item py-2">
                          <div class="media align-items-center">
                            <div class="media-body">
                              <p class="small text-muted mb-0">
                                {{ tm("dataIncio") }}
                              </p>
                              <p class="mb-0">
                                {{
                                  moment(store.state.usuario.dataInicio)
                                    .tz(timezoneDefault)
                                    .format("DD/MM/YYYY HH:mm")
                                }}
                              </p>
                            </div>
                            <!-- Default :: Inline SVG -->
                            <svg
                              class="text-muted hw-20 ml-1"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>

                            <!-- Alternate :: External File link -->
                            <!-- <img class="injectable text-muted hw-20 ml-1" src="./../assets/media/heroicons/outline/clock.svg" alt=""> -->
                          </div>
                        </li>
                        <li class="list-group-item py-2">
                          <div class="media align-items-center">
                            <div class="media-body">
                              <p class="small text-muted mb-0">
                                {{ tm("interacoesSimultaneas") }}
                              </p>
                              <p class="mb-0">{{ refAtendimento }}</p>
                            </div>
                            <svg
                              class="text-muted hw-20 ml-1"
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              width="24"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                              />
                            </svg>
                            <!-- Alternate :: External File link -->
                            <!-- <img class="injectable text-muted hw-20 ml-1" src="./../assets/media/heroicons/outline/phone.svg" alt=""> -->
                          </div>
                        </li>
                      </ul>
                      <!-- List Group End -->
                    </div>
                    <!-- Card End -->
                    <!-- Card Start -->
                    <!-- Card End -->
                  </div>
                </div>
              </div>
              <!-- Sidebar Content End -->
            </div>
          </div>
        </div>
        <!-- Profile Tab Content End -->
      </div>
      <!-- Tab Content End -->
    </aside>
    <!-- Sidebar End -->
    <!-- Main Start -->
    <main class="main" :class="store.state.mainTab ? 'main-visible ' : ''">
      <!-- Chats Page Start -->
      <div class="chats w-100" v-if="store.state.navTab === mutationsTypes.CHATS">
        <div class="chat-body w-100">
          <!-- Chat Header Start-->
          <div v-if="selectedChatQueue" class="chat-header position-relative mb-1">
            <!-- Chat Back Button (Visible only in Small Devices) -->
            <button
              class="btn btn-secondary btn-icon btn-minimal btn-sm text-muted d-xl-none"
              type="button"
              data-close=""
            >
              <!-- Default :: Inline SVG -->
              <svg
                @click="setMainTab(false)"
                class="hw-20"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                />
              </svg>

              <!-- Alternate :: External File link -->
              <!-- <img class="injectable hw-20" src="./../assets/media/heroicons/outline/arrow-left.svg" alt=""> -->
            </button>

            <!-- Chat participant's Name -->
            <div class="media chat-name align-items-center text-truncate">
              <div class="avatar d-none d-sm-inline-block mr-3">
                <img
                  :src="require(`@/assets/images/${selectedChatQueue.channel}.png`)"
                  alt=""
                />
              </div>

              <div class="lign-self-center" style="width: 40%">
                <h6 class="text-truncate mb-0">
                  {{
                    selectedChatQueue.contactName
                      ? selectedChatQueue.contactName +
                        " (" +
                        selectedChatQueue.chatId +
                        ")"
                      : selectedChatQueue.chatId
                  }}
                </h6>
                <small class="text-muted">{{
                  selectedChatQueue.flowName
                    ? selectedChatQueue.queueId + " - " + selectedChatQueue.flowName
                    : selectedChatQueue.queueId
                }}</small>
                <span
                  v-if="isMarketplace(selectedChatQueue.channel)"
                  class="ml-1 badge badge-danger"
                >
                  {{ getSla(selectedChatQueue) }}
                </span>
              </div>
              <div v-if="!isEmbedded">
                <h6 class="text-truncate mb-0 mr-2" :title="campanhaUsuario">
                  {{ campanhaUsuario }}
                </h6>
              </div>
            </div>

            <!-- Chat Options -->
            <div class="nav flex-nowrap">
              <a
                v-if="!webFlowConfiguration"
                :title="tm('finalizarAtendimento')"
                class="align-items-center d-flex"
                href="#"
                data-toggle="modal"
                data-target="#finalizarAtendimento"
                @click="carregarTabulacoes"
              >
                <!-- Default :: Inline SVG -->
                <svg
                  class="hw-20 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  width="24"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </a>
              <a
                v-if="!webFlowConfiguration && exibirBtnDownloadAtend"
                :title="tm('exportarchat')"
                class="align-items-center d-flex"
                href="#"
                @click="exportarChat"
              >
                <!-- Default :: Inline SVG -->
                <svg
                  class="hw-20 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  width="24"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                  />
                </svg>
              </a>
              <a
                v-if="!webFlowConfiguration && exibirBtnTransferirAtend"
                :title="tm('transferir')"
                class="align-items-center d-flex"
                href="#"
                role="button"
                data-toggle="modal"
                data-target="#transferir"
                @click="clearTransferir"
              >
                <!-- Default :: Inline SVG -->
                <svg
                  class="hw-20 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  width="24"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </a>
              <a
                title="Apps"
                class="align-items-center d-flex hide-appbar-btn"
                href="#"
                data-toggle-appbar
                data-target=".appbar"
                style="color: #b9b9b9 !important"
              >
                <!--Default :: Inline SVG-->
                <svg
                  class="hw-20"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                  ></path>
                </svg>
              </a>
            </div>

            <div
              id="atendimento-container"
              v-if="atendimentoAvailable"
              class="position-absolute w-100 atendimento-container"
              :class="{ open: toggleAppbarMobile }"
            >
              <div class="w-100 h-100 atendimento-div pb-1" style="overflow: auto">
                <atendimento
                  ref="atendimentoCmp"
                  @close="appBarClear"
                  @enviarCatalogo="sendCatalog"
                  @enviarDescricaoProduto="sendProductDescription"
                  @enviarMsgChat="sendMsgChat"
                />
              </div>
              <div
                class="float-right mr-2 atendimento-container"
                style="
                  cursor: pointer;
                  height: 2rem;
                  width: 3.5rem;
                  border-bottom-right-radius: 100% !important;
                  border-bottom-left-radius: 100% !important;
                "
              >
                <a
                  :title="tm('atendimento')"
                  class="align-items-center d-flex px-1 data-toggle-appbarmobile"
                  :class="{ active: toggleAppbarMobile }"
                  href="#"
                  @click="toggleATHBar"
                  style="color: #b9b9b9 !important"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    width="24"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                  <svg
                    class="arrowdown"
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    width="24"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
                    />
                  </svg>
                  <svg
                    class="arrowup"
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    width="24"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 11l7-7 7 7M5 19l7-7 7 7"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <!-- Chat Header End-->
          <div v-if="tagsAvailable && selectedChatQueue" class="ml-1 mr-1 pl-1 pr-1 mb-1">
            <vue-tags-input
              v-model="autocompleteTag"
              :tags="autocompleteTags"
              class="tag-custom text-15"
              :autocomplete-items="filteredItems"
              :autocomplete-min-length="0"
              :delete-on-backspace="false"
              @tags-changed="atualizaTags"
              @before-adding-tag="incluiTag"
              :placeholder="
                selectedChatQueue.chatUUID
                  ? tagsCadastroAvailable
                    ? '+ Tag'
                    : tm('msgTag01')
                  : tm('msgTag03')
              "
              :title="tm('conversationTags')"
              :disabled="!selectedChatQueue.chatUUID"
            >
              <template v-if="tagsCadastroAvailable" v-slot:autocomplete-header>
                <small>
                  <em>{{ tm("msgTag01") }}</em>
                </small>
              </template>
              <template v-if="tagsCadastroAvailable" v-slot:autocomplete-footer>
                <small>
                  <em>{{ tm("msgTag02") }}</em>
                </small>
              </template>

              <template v-slot:autocomplete-item="slotProps" class="p-1">
                <span
                  :class="
                    slotProps && slotProps.item && slotProps.item.color
                      ? 'ti-item-dinamico'
                      : 'ti-item-custom'
                  "
                  @click="slotProps.performAdd(slotProps.item)"
                  :style="{ background: slotProps.item.color }"
                  style="cursor: pointer"
                >
                  {{ slotProps && slotProps.item ? slotProps.item.text : "" }}</span
                >
              </template>

              <template v-slot:tag-center="props">
                <span
                  :class="
                    props && props.tag && props.tag.color
                      ? 'ti-item-dinamico'
                      : 'ti-item-custom'
                  "
                  :style="{ background: props.tag.color }"
                >
                  {{ props && props.tag ? props.tag.text : "" }}</span
                >
              </template>
            </vue-tags-input>
          </div>
          <!-- 31.03.2022 - Ana Ribeiro - Marketplace - inicio  -->
          <div
            class="ml-1 mr-1 pl-1 pr-1 mb-1"
            v-if="marketplaceAvailable && headerTicketConfiguration"
          >
            <header-ticket :ticket="selectedChatQueue" />
          </div>
          <!-- 31.03.2022 - Ana Ribeiro - Marketplace - fim  -->
          <!-- Chat Content Start-->
          <div
            class="chat-content scrolling p-2 position-relative"
            id="messageBody"
            ref="messageBody"
          >
            <!-- Container Common Start -->
            <div
              class="container"
              v-if="
                selectedChatQueue &&
                selectedChatQueue.channel != constants.TipoCanal.indexOf('EMAIL')
              "
            >
              <!-- Message Day Start -->
              <div class="message-day" id="messageday" ref="messageday">
                <div v-for="message in currentChatMessages" :key="message.internalId">
                  <!-- Received Message Start -->
                  <div v-if="message.incoming" class="message">
                    <div class="message-wrapper">
                      <div v-if="Validar.isText(message.message)" class="message-content">
                        <div
                          class="mb-2"
                          v-if="
                            message.messageContextId &&
                            getMentionedMessage(message.messageContextId) != ''
                          "
                        >
                          <mentioned-message
                            :from="getMentionedFrom(message.messageContextId)"
                            :message="getMentionedMessage(message.messageContextId)"
                          />
                        </div>

                        <span
                          style="position: relative; overflow-wrap: break-word"
                          v-html="message.message"
                        ></span>
                      </div>
                      <div
                        v-if="Validar.isImage(message.message)"
                        class="message-content"
                      >
                        <div
                          class="mb-2"
                          v-if="
                            message.messageContextId &&
                            getMentionedMessage(message.messageContextId) != ''
                          "
                        >
                          <mentioned-message
                            :from="getMentionedFrom(message.messageContextId)"
                            :message="getMentionedMessage(message.messageContextId)"
                          />
                        </div>
                        <a
                          target="_blank"
                          :href="Validar.returnImageURL(message.message)"
                        >
                          <img
                            :src="Validar.returnImageURL(message.message)"
                            alt=""
                            style="
                              height: 50px !important;
                              width: 50px !important;
                              max-height: 50px !important;
                              max-width: 50px !important;
                            "
                          />
                        </a>
                        <br /><span
                          v-html="Validar.returnImageText(message.message)"
                        ></span>
                      </div>
                      <div
                        v-if="Validar.isDocument(message.message)"
                        class="message-content"
                      >
                        <div
                          class="mb-2"
                          v-if="
                            message.messageContextId &&
                            getMentionedMessage(message.messageContextId) != ''
                          "
                        >
                          <mentioned-message
                            :from="getMentionedFrom(message.messageContextId)"
                            :message="getMentionedMessage(message.messageContextId)"
                          />
                        </div>
                        <a target="_blank" :href="Validar.returnURL(message.message)">
                          <img :src="require(`@/assets/images/file.png`)" alt="" />
                        </a>
                        <br /><span
                          v-html="Validar.returnImageText(message.message)"
                        ></span>
                      </div>
                      <div
                        v-if="
                          Validar.isAudio(message.message) ||
                          Validar.isVoice(message.message)
                        "
                        class="message-content"
                      >
                        <div
                          class="mb-2"
                          v-if="
                            message.messageContextId &&
                            getMentionedMessage(message.messageContextId) != ''
                          "
                        >
                          <mentioned-message
                            :from="getMentionedFrom(message.messageContextId)"
                            :message="getMentionedMessage(message.messageContextId)"
                          />
                        </div>
                        <audio
                          controls="x"
                          :src="Validar.returnURL(message.message)"
                          style="max-width: 90%"
                        >
                          Your browser does not support the audio element.
                        </audio>
                      </div>
                      <div
                        v-if="Validar.isVideo(message.message)"
                        class="message-content"
                      >
                        <div
                          class="mb-2"
                          v-if="
                            message.messageContextId &&
                            getMentionedMessage(message.messageContextId) != ''
                          "
                        >
                          <mentioned-message
                            :from="getMentionedFrom(message.messageContextId)"
                            :message="getMentionedMessage(message.messageContextId)"
                          />
                        </div>
                        <video
                          controls="x"
                          :src="Validar.returnURL(message.message)"
                          style="max-height: 300px"
                        >
                          Your browser does not support the video element.
                        </video>
                        <br /><span
                          v-html="Validar.returnImageText(message.message)"
                        ></span>
                      </div>
                    </div>
                    <div
                      v-if="!Validar.isSystem(message.message)"
                      class="message-options"
                    >
                      <div class="avatar avatar-sm">
                        <img
                          alt=""
                          :src="require(`@/assets/images/${message.agentId == 'bot'? 'android': message.channel}.png`)"
                        />
                      </div>
                      <span v-if="message.timestamp" class="message-date">{{
                        moment(message.timestamp)
                          .tz(timezoneDefault)
                          .format("DD/MM/YYYY HH:mm")
                      }}</span>
                    </div>
                  </div>
                  <!-- Received Message End -->
                  <!-- Self Message Start -->
                  <div v-else class="message self">
                    <div class="message-wrapper">
                      <div v-if="Validar.isText(message.message)" class="message-content">
                        <div
                          class="mb-2"
                          v-if="
                            message.messageContextId &&
                            getMentionedMessage(message.messageContextId) != ''
                          "
                        >
                          <mentioned-message
                            :from="getMentionedFrom(message.messageContextId)"
                            :message="getMentionedMessage(message.messageContextId)"
                          />
                        </div>
                        <span
                          style="position: relative; overflow-wrap: break-word"
                          v-html="message.message"
                        ></span>
                      </div>
                      <div
                        v-if="Validar.isImage(message.message)"
                        class="message-content"
                      >
                        <div
                          class="mb-2"
                          v-if="
                            message.messageContextId &&
                            getMentionedMessage(message.messageContextId) != ''
                          "
                        >
                          <mentioned-message
                            :from="getMentionedFrom(message.messageContextId)"
                            :message="getMentionedMessage(message.messageContextId)"
                          />
                        </div>
                        <a
                          target="_blank"
                          :href="Validar.returnImageURL(message.message)"
                        >
                          <img
                            :src="Validar.returnImageURL(message.message)"
                            alt=""
                            style="
                              height: 50px !important;
                              width: 50px !important;
                              max-height: 50px !important;
                              max-width: 50px !important;
                            "
                          />
                        </a>
                        <br /><span
                          v-html="Validar.returnImageText(message.message)"
                        ></span>
                      </div>
                      <div
                        v-if="Validar.isDocument(message.message)"
                        class="message-content"
                      >
                        <div
                          class="mb-2"
                          v-if="
                            message.messageContextId &&
                            getMentionedMessage(message.messageContextId) != ''
                          "
                        >
                          <mentioned-message
                            :from="getMentionedFrom(message.messageContextId)"
                            :message="getMentionedMessage(message.messageContextId)"
                          />
                        </div>
                        <a target="_blank" :href="Validar.returnURL(message.message)">
                          <img :src="require(`@/assets/images/file.png`)" alt="" />
                        </a>
                        <br /><span
                          v-html="Validar.returnImageText(message.message)"
                        ></span>
                      </div>
                      <div
                        v-if="
                          Validar.isAudio(message.message) ||
                          Validar.isVoice(message.message)
                        "
                        class="message-content"
                      >
                        <div
                          class="mb-2"
                          v-if="
                            message.messageContextId &&
                            getMentionedMessage(message.messageContextId) != ''
                          "
                        >
                          <mentioned-message
                            :from="getMentionedFrom(message.messageContextId)"
                            :message="getMentionedMessage(message.messageContextId)"
                          />
                        </div>
                        <audio
                          controls="x"
                          :src="Validar.returnURL(message.message)"
                          style="max-width: 90%"
                        >
                          Your browser does not support the audio element.
                        </audio>
                      </div>
                      <div
                        v-if="Validar.isVideo(message.message)"
                        class="message-content"
                      >
                        <div
                          class="mb-2"
                          v-if="
                            message.messageContextId &&
                            getMentionedMessage(message.messageContextId) != ''
                          "
                        >
                          <mentioned-message
                            :from="getMentionedFrom(message.messageContextId)"
                            :message="getMentionedMessage(message.messageContextId)"
                          />
                        </div>
                        <video
                          controls="x"
                          :src="Validar.returnURL(message.message)"
                          style="max-height: 300px"
                        >
                          Your browser does not support the video element.
                        </video>
                        <br /><span
                          v-html="Validar.returnImageText(message.message)"
                        ></span>
                      </div>
                    </div>
                    <div
                      v-if="!Validar.isSystem(message.message)"
                      class="message-options"
                    >
                      <div class="avatar avatar-sm">
                        <img alt="" src="./../assets/images/icon-user.png" />
                      </div>

                      <span v-if="message.timestamp" class="message-date">{{
                        moment(message.timestamp)
                          .tz(timezoneDefault)
                          .format("DD/MM/YYYY HH:mm")
                      }}</span>
                    </div>
                  </div>
                </div>
                <!-- Self Message End -->
              </div>
              <!-- Message Day End -->
            </div>

            <!-- Container Common End -->
            <!--<div v-if="message.incoming" class="message">
              <div class="message-options">
                <div class="avatar avatar-sm"><img alt="" :src="require(`@/assets/images/${message.channel}.png`)"></div>
                <span v-if="message.timestamp" class="message-date">{{ moment(message.timestamp).tz("timezoneDefault").format("DD/MM/YYYY HH:mm") }}</span>
              </div>
            </div>-->
            <!-- Container eMail Start -->
            <div
              class="container"
              v-if="
                selectedChatQueue &&
                selectedChatQueue.channel == constants.TipoCanal.indexOf('EMAIL')
              "
            >
              <!-- Message Day Start -->
              <div class="generic-position">
                <div class="row">
                  <div class="col-12">
                    <div class="right-box-padding">
                      <div class="read-content">
                        <div class="media">
                          <div class="media-body">
                            <div class="row">
                              <div class="col-12">
                                <h6
                                  class="read-content-email float-left mb-0"
                                  style="line-height: 2rem"
                                >
                                  {{ tm("remetente") }}:
                                </h6>
                                <h5
                                  :title="`${selectedChatQueue.email.remetente.nome}`"
                                  class="text-dark float-left mx-2 mb-0 w-100"
                                  v-if="!isRemetenteAlterado"
                                  style="
                                    line-height: 2rem;
                                    white-space: nowrap;
                                    overflow-x: hidden;
                                    text-overflow: ellipsis;
                                    max-width: 85%;
                                  "
                                >
                                  {{ selectedChatQueue.email.remetente.nome }}({{
                                    selectedChatQueue.email.remetente.email
                                  }})
                                  <a
                                    href="javascript:void(0)"
                                    v-if="exibirBtnEditarRemetente"
                                    class="mx-2"
                                    :title="tm('editar')"
                                    @click="changeRemetentePadrao"
                                    ><i class="far fa-edit"></i
                                  ></a>
                                </h5>
                                <div
                                  v-if="exibirBtnEditarRemetente && isRemetenteAlterado"
                                >
                                  <!--<input class="form-control w-auto d-inline-block mx-2" v-model="novoRemetenteEmail">-->
                                  <InputChips class="mx-2" :chips="novoRemetenteEmail" />
                                  <a
                                    href="javascript:void(0)"
                                    class="float-left"
                                    :title="tm('desfazer')"
                                    @click="changeRemetentePadrao"
                                    ><i class="fas fa-undo-alt"></i
                                  ></a>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <h6
                                  class="read-content-email float-left mb-0"
                                  style="line-height: 2rem"
                                >
                                  Para:
                                </h6>
                                <h6 class="mx-2" style="line-height: 2rem">
                                  <span
                                    v-for="(destinatario, index) in selectedChatQueue
                                      .email.listaDestinatario"
                                    class="ml-1"
                                    :key="index"
                                    >{{ destinatario.nome }}({{ destinatario.email }});
                                  </span>
                                </h6>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <h6
                                  class="read-content-email float-left mb-0"
                                  style="line-height: 2rem"
                                >
                                  CC:
                                </h6>
                                <h6 class="mx-2" style="line-height: 2rem">
                                  <span
                                    v-for="(cc, index) in selectedChatQueue.email.listaCC"
                                    class="ml-1"
                                    :key="index"
                                    >{{ cc.nome }}({{ cc.email }});</span
                                  >
                                </h6>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <h6
                                  class="read-content-email float-left mb-0"
                                  style="line-height: 2rem"
                                >
                                  {{ tm("data") + "/" + tm("hora") + ":" }}
                                </h6>
                                <h6 class="mx-2" style="line-height: 2rem">
                                  <span
                                    v-if="
                                      selectedChatQueue.channel ==
                                      constants.TipoCanal.indexOf('EMAIL')
                                    "
                                    class="ml-1"
                                    >{{
                                      moment(selectedChatQueue.email.data)
                                        .tz(timezoneDefault)
                                        .format("DD/MM/YYYY HH:mm")
                                    }}</span
                                  >
                                  <span v-else class="ml-1">{{
                                    moment(selectedChatQueue.dateAdded)
                                      .tz(timezoneDefault)
                                      .format("DD/MM/YYYY HH:mm")
                                  }}</span>
                                </h6>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <h6
                                  class="read-content-email float-left mb-0"
                                  style="line-height: 1.5rem"
                                >
                                  {{ tm("assunto") }}:
                                </h6>
                                <h5
                                  :title="`${selectedChatQueue.email.assunto}`"
                                  class="text-primary float-left mx-2 mb-0 font-weight-bold"
                                  style="
                                    line-height: 1.5rem;
                                    white-space: nowrap;
                                    overflow-x: hidden;
                                    text-overflow: ellipsis;
                                    max-width: 85%;
                                  "
                                >
                                  {{ selectedChatQueue.email.assunto }}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr class="mt-2 mb-3" />
                        <div class="read-content-body ck-content">
                          <span v-html="selectedChatQueue.email.corpo"> </span>
                          <hr />
                        </div>
                        <div
                          class="read-content-attachment"
                          v-if="selectedChatQueue.email.possuiAnexo"
                        >
                          <h6>
                            <i class="fa fa-download"></i> Anexos
                            <span>({{ selectedChatQueue.email.listaAnexo.length }})</span>
                          </h6>
                          <div class="row attachment">
                            <div
                              class="col-auto"
                              v-for="(anex, index) in selectedChatQueue.email.listaAnexo"
                              style="font-size: 0.8rem"
                              :key="index"
                            >
                              <a
                                :href="`${anex.caminhoUrl}`"
                                class="text-muted"
                                :target="`${anex.nome}`"
                                :title="`${anex.nome}`"
                                >{{ anex.nome
                                }}<small class="ml-1 text-muted"
                                  >({{ bytesToSize(anex.tamanho) }})</small
                                ></a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Message Day End -->
            </div>
            <!-- Container eMail End -->
            <!-- Scroll to finish -->
            <div class="chat-finished" id="#"></div>
          </div>
          <!-- Chat Content End-->
          <!-- Chat Footer Start-->
          <div
            v-if="
              selectedChatQueue &&
              selectedChatQueue.channel != constants.TipoCanal.indexOf('EMAIL')
            "
            class="chat-footer"
          >
            <div
              class="instant-messages mx-2 p-1"
              v-if="showInstanMessages"
              style="z-index: 10"
            >
              <table class="table m-0">
                <tbody
                  class="mainBody scrolling"
                  style="overflow: auto; height: 200px; width: 100%"
                >
                  <tr v-for="sugestaoMsg in instantMessages" :key="sugestaoMsg.mensagem">
                    <td>
                      <a href="#" @click="setMessage(sugestaoMsg.mensagem)">
                        <span>{{ sugestaoMsg.mensagem }}</span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="attachment py-2">
              <div
                class="float-left dropdown border-0 text-center"
                v-if="msgRapidaAvailable"
              >
                <a
                  class="float-left"
                  :title="tm('mensagemRapida')"
                  href="#"
                  role="button"
                  data-toggle="modal"
                  data-target="#msgRapida"
                  @click="clearMsgRapida"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    width="24"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    />
                  </svg>
                </a>
              </div>

              <div class="dropdown border-0" v-if="emojiAvailable">
                <a class="float-left text-warning" title="Emoji" href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    width="24"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </a>
                <div class="dropdown-content rounded emoji-panel">
                  <Emoji @emojiAppend="appendEmoji" /><!--displayEmojiTab-->
                </div>
              </div>

              <div class="float-left dropdown border-0" v-show="exibirBtnSendAnexo">
                <a
                  :title="tm('enviar') + ' ' + tm('arquivo')"
                  class="p-0"
                  href="#"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style="height: 1.7rem"
                >
                  <svg
                    class="hw-20"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                    />
                  </svg>
                </a>
                <div class="dropdown-menu">
                  <a
                    :title="tm('enviar') + ' ' + tm('imagem')"
                    class="dropdown-item"
                    href="#"
                    data-toggle="modal"
                    data-target="#enviarImagem"
                    @click="clearFile"
                  >
                    <svg
                      class="hw-20 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                    <!-- <img class="injectable hw-20 mr-2" src="./../assets/media/heroicons/outline/photograph.svg" alt=""> -->
                    <span>{{ tm("imagem") }}</span>
                  </a>
                  <a
                    :title="tm('enviar') + ' ' + tm('audio')"
                    class="dropdown-item"
                    href="#"
                    data-toggle="modal"
                    data-target="#enviarAudio"
                    @click="clearFile"
                  >
                    <svg
                      class="hw-20 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z"
                      />
                    </svg>
                    <!-- <img class="injectable hw-20 mr-2" src="./../assets/media/heroicons/outline/volume-up.svg" alt=""> -->
                    <span>{{ tm("audio") }}</span>
                  </a>
                  <a
                    :title="tm('enviar') + ' ' + tm('documento')"
                    class="dropdown-item"
                    href="#"
                    data-toggle="modal"
                    data-target="#enviarDocumento"
                    @click="clearFile"
                  >
                    <svg
                      class="hw-20 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                      />
                    </svg>
                    <!-- <img class="injectable hw-20 mr-2" src="./../assets/media/heroicons/outline/document.svg" alt=""> -->
                    <span>{{ tm("documento") }}</span>
                  </a>
                  <a
                    :title="tm('enviar') + ' ' + tm('video')"
                    class="dropdown-item"
                    href="#"
                    data-toggle="modal"
                    data-target="#enviarVideo"
                    @click="clearFile"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="hw-20 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    <!-- <img class="injectable hw-20 mr-2" src="./../assets/media/heroicons/outline/user.svg" alt=""> -->
                    <span>{{ tm("video") }}</span>
                  </a>
                </div>
              </div>

              <div class="float-left dropdown border-0 text-center" v-show="exibirBtnAudio">
                <a
                  :title="tm('gravarAudio')"
                  v-if="!isRecording"
                  class="float-left"
                  href="#"
                  role="button"
                  @click="startRecord"
                  style="width: 1.6rem"
                >
                  <i class="fas fa-microphone"></i>
                </a>
                <a
                  :title="tm('pararGravarAudio')"
                  v-if="isRecording"
                  class="float-left text-reset"
                  href="#"
                  role="button"
                  @click="stopRecord"
                  style="width: 1.6rem"
                >
                  <i class="fas fa-stop" style="color: red"></i>
                  <small
                    v-if="isRecording"
                    style="
                      display: block;
                      margin-left: 6px;
                      height: 0px;
                      margin: 0px;
                      font-size: x-small;
                      margin-top: -7px;
                    "
                  >
                    {{ timeText }}
                  </small>
                </a>
              </div>
            </div>

            <div
              v-if="
                selectedChatQueue &&
                selectedChatQueue.channel != constants.TipoCanal.indexOf('EMAIL') &&
                showBotaoRolagem 
              "
              @click="scrollToBottomSelected"
              class="showBotaoRolagem text-center px-2 py-1 rounded position-absolute"
              style="
                right: 50%;
                transform: translateX(50%);
                cursor: pointer;
                white-space: nowrap;
                bottom: 7rem;
                background: #fffd;
                border: 1px solid #e5e9f2;
              "
            >
              {{ tm("mensagemConversa01") }}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                width="24"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
                />
              </svg>
            </div>

            <textarea
              v-model="message"
              v-on:keyup="exibeInstantMessages"
              v-on:keydown.enter.exact="sendMessage"
              class="form-control emojionearea-form-control m-2 scrolling"
              id="messageInput"
              rows="1"
              :placeholder="tm('mensagemEmail01')"
            ></textarea>

            <div
              @click="sendMessage"
              class="btn btn-primary btn-icon send-icon rounded-circle text-light mb-1"
              role="button"
            >
              <svg class="hw-24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
              <!-- <img src="./../assets/media/heroicons/outline/arrow-right.svg" alt="" class="injectable"> -->
            </div>
          </div>

          <div
            v-if="
              selectedChatQueue &&
              selectedChatQueue.channel == constants.TipoCanal.indexOf('EMAIL')
            "
            class="chat-footer"
          >
            <hr class="mx-2" />
            <div class="container" style="max-width: unset">
              <div class="form-group">
                <div class="float-left dropdown border-0">
                  <a
                    :title="tm('anexarArquivo')"
                    class="btn btn-secondary btn-icon btn-minimal btn-sm p-0"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style="height: 1.7rem"
                  >
                    <svg
                      class="hw-20"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                      />
                    </svg>
                  </a>
                  <div class="dropdown-menu">
                    <a
                      class="dropdown-item"
                      href="#"
                      data-toggle="modal"
                      data-target="#enviarImagem"
                      @click="clearFile"
                    >
                      <svg
                        class="hw-20 mr-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                        />
                      </svg>
                      <!-- <img class="injectable hw-20 mr-2" src="./../assets/media/heroicons/outline/photograph.svg" alt=""> -->
                      <span>{{ tm("imagem") }}</span>
                    </a>
                    <a
                      class="dropdown-item"
                      href="#"
                      data-toggle="modal"
                      data-target="#enviarAudio"
                      @click="clearFile"
                    >
                      <svg
                        class="hw-20 mr-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z"
                        />
                      </svg>
                      <!-- <img class="injectable hw-20 mr-2" src="./../assets/media/heroicons/outline/volume-up.svg" alt=""> -->
                      <span>{{ tm("audio") }}</span>
                    </a>
                    <a
                      class="dropdown-item"
                      href="#"
                      data-toggle="modal"
                      data-target="#enviarDocumento"
                      @click="clearFile"
                    >
                      <svg
                        class="hw-20 mr-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                        />
                      </svg>
                      <!-- <img class="injectable hw-20 mr-2" src="./../assets/media/heroicons/outline/document.svg" alt=""> -->
                      <span>{{ tm("documento") }}</span>
                    </a>
                    <a
                      class="dropdown-item"
                      href="#"
                      data-toggle="modal"
                      data-target="#enviarVideo"
                      @click="clearFile"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="hw-20 mr-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                      <!-- <img class="injectable hw-20 mr-2" src="./../assets/media/heroicons/outline/user.svg" alt=""> -->
                      <span>{{ tm("video") }}</span>
                    </a>
                  </div>
                </div>
                <div>{{ tm("anexar") }}</div>
              </div>
              <div class="form-group">
                <div
                  class="col-auto"
                  v-for="(anex, index) in listaAnexoEmail"
                  style="font-size: 0.8rem"
                  :key="anex.id"
                >
                  <a
                    :href="`${anex.caminhoUrl}`"
                    class="text-muted"
                    :target="`${anex.nome}`"
                    :title="`${anex.nome}`"
                    >{{ anex.nome
                    }}<small class="ml-1 text-muted"
                      >({{ bytesToSize(anex.tamanho) }})</small
                    ></a
                  >
                  <a href="#">
                    <svg
                      class="hw-20"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      @click="excluirAnexo(anex, index)"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
              <div class="form-group">
                <ckeditor
                  id="ckeditor"
                  :editor="editor"
                  v-model="message"
                  :config="editorConfig"
                ></ckeditor>
                <!--<textarea prefix="ckeditor" id="ckeditor" v-model="message" class="d-none"></textarea>-->
              </div>
              <div class="text-right position-absolute float-right send-button">
                <button
                  :disabled="isloadingEmail"
                  class="btn btn-primary btn-icon send-icon rounded-circle text-light mb-1"
                  type="button"
                  @click="enviarEmail"
                >
                  <svg
                    class="hw-24"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                  <div
                    v-if="isloadingEmail"
                    class="spinner-border spinner-border-sm"
                    role="status"
                  ></div>
                </button>
              </div>
            </div>
          </div>
          <!-- Chat Footer End-->
        </div>
      </div>
      <!-- Chats Page End -->
      <!-- Profile Settings Start -->
      <div class="profile" v-if="store.state.navTab === mutationsTypes.PROFILE">
        <div class="container-xl px-2 px-sm-3">
          <div class="row">
            <div class="col">
              <div class="card mb-3">
                <div class="card-header">
                  <button
                    class="btn btn-secondary btn-icon btn-minimal btn-sm text-muted d-xl-none"
                    type="button"
                    data-close=""
                  >
                    <svg
                      @click="setMainTab(false)"
                      class="hw-20"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M10 19l-7-7m0 0l7-7m-7 7h18"
                      />
                    </svg>
                  </button>
                  <h5 class="mb-1">{{ tm("configuracoes") }}</h5>
                  <p class="mb-0 text-muted">
                    {{ tm("dadosPessoais") }} &amp; {{ tm("contato") }}
                  </p>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="firstName">{{ tm("nome") }}</label>
                        <input
                          type="text"
                          disabled
                          class="form-control form-control-md"
                          id="firstName"
                          placeholder=""
                          v-model="nomeUsuario"
                        />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="emailAddress">Email</label>
                        <input
                          type="email"
                          disabled
                          class="form-control form-control-md"
                          id="emailAddress"
                          placeholder=""
                          v-model="emailUsuario"
                        />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="empresa">{{ tm("empresa") }}</label>
                        <input
                          type="text"
                          disabled
                          class="form-control form-control-md"
                          id="empresa"
                          v-model="empresaUsuario"
                        />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="supervisor">{{ tm("supervisor") }}</label>
                        <input
                          type="text"
                          disabled
                          class="form-control form-control-md"
                          id="supervisor"
                          placeholder=""
                          v-model="supervisorUsuario"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="false" class="card mb-3">
                <div class="card-header">
                  <h6 class="mb-1">{{ tm("senha") }}</h6>
                  <p class="mb-0 text-muted small">{{ tm("alterarSenha") }}</p>
                </div>
                <div class="card-body">
                  <form>
                    <div class="row">
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <label for="current-password">{{ tm("senhaAtual") }}</label>
                          <input
                            type="password"
                            disabled
                            class="form-control form-control-md"
                            id="current-password"
                            :placeholder="tm('senhaAtual')"
                            autocomplete="on"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <label for="new-password">{{ tm("novaSenha") }}</label>
                          <input
                            type="password"
                            disabled
                            class="form-control form-control-md"
                            id="new-password"
                            :placeholder="tm('novaSenha')"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <label for="repeat-password">{{ tm("repetirSenha") }}</label>
                          <input
                            type="password"
                            disabled
                            class="form-control form-control-md"
                            id="repeat-password"
                            :placeholder="tm('repetirSenha')"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="card-footer d-flex justify-content-end">
                  <button type="button" disabled class="btn btn-link text-muted mx-1">
                    {{ tm("limpar") }}
                  </button>
                  <button type="button" disabled class="btn btn-primary">
                    {{ tm("salvar") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Profile Settings End -->
    </main>
    <!-- Main End -->
    <!-- App Add-ons Start -->
    <div class="appbar appbar-ath">
      <div class="appbar-wrapper hide-scrollbar">
        <!-- Chat Back Button (Visible only in Small Devices) -->
        <div class="d-flex justify-content-center border-bottom w-100">
          <a
            class="btn btn-secondary btn-icon m-0 btn-minimal btn-sm text-muted d-xl-none"
            role="button"
            data-apps-close=""
          >
            <!-- Default :: Inline SVG -->
            <svg class="hw-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
            <!-- Alternate :: External File link -->
            <!-- <img class="injectable hw-20" src="./../assets/media/heroicons/outline/arrow-left.svg" alt=""> -->
          </a>
        </div>
        <div class="appbar-head">
          <!-- Default :: Inline SVG -->
          <svg
            class="hw-20"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            height="30"
            width="30"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
            ></path>
          </svg>
          <!-- Alternate :: External File link -->
          <!-- <img class="hw-20" src="./../assets/media/heroicons/outline/view-grid.svg" alt="" class="injectable hw-20"> -->
          <h6 class="mb-0 mt-1">Apps</h6>
        </div>
        <!-- Appbar Nav Start -->
        <ul class="nav nav-minimal appbar-nav" id="appNavTab" role="tablist">
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="todo-tab"
              data-toggle="tab"
              href="#todo"
              @click="loadIntroTutorial"
              role="tab"
              aria-controls="todo"
              aria-selected="false"
              :title="tm('ajuda')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="30"
                width="30"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </a>
          </li>
          <li
            class="nav-item"
            role="presentation"
            v-if="selectedChatQueue && clienteAvailable"
          >
            <a
              class="nav-link"
              id="cliente-tab"
              data-toggle="tab"
              href="#cliente"
              @click="setCliente"
              role="tab"
              aria-controls="cliente"
              aria-selected="false"
              :title="tm('crmCliente')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="30"
                width="30"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </li>
          <li
            class="nav-item atendimento-appbar"
            role="presentation"
            v-if="atendimentoAvailable"
          >
            <a
              class="nav-link"
              id="atendimento-tab"
              data-toggle="tab"
              href="#atendimento"
              @click="setAtendimento"
              role="tab"
              aria-controls="atendimento"
              aria-selected="false"
              :title="tm('atendimentoHumano')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                width="24"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                />
              </svg>
            </a>
          </li>
          <!-- 30.03.2022 - Ana Ribeiro - Marketplace - inicio  -->
          <li
            class="nav-item"
            role="presentation"
            v-if="marketplaceAvailable || pluginMarketplace"
          >
            <a
              class="nav-link"
              id="marketplace-tab"
              data-toggle="tab"
              href="#marketplace"
              @click="setMarketplace"
              role="tab"
              aria-controls="marketplace"
              aria-selected="false"
              :title="tm('buscaPedido')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                width="24"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                />
              </svg>
            </a>
          </li>
          <!-- 30.03.2022 - Ana Ribeiro - Marketplace - fim  -->
          <li class="nav-item" role="presentation" v-if="agendaAvailable">
            <a
              class="nav-link"
              id="agenda-tab"
              data-toggle="tab"
              href="#agenda"
              @click="setAgenda"
              role="tab"
              aria-controls="agenda"
              aria-selected="false"
              :title="tm('agenda')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20"
                width="20"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </li>

          <!-- 14.09.2021 - Ivanildo Bacelar - Historico de atendimento - início -->
          <li
            class="nav-item"
            role="presentation"
            v-if="selectedChatQueue && historicoAvailable"
          >
            <a
              class="nav-link"
              id="chat-history-tab"
              data-toggle="tab"
              href="#chat-history"
              @click="setHistorico"
              role="tab"
              aria-controls="chat-history"
              aria-selected="false"
              :title="tm('historicoAtendimento')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                width="24"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                />
              </svg>
            </a>
          </li>
          <!-- 14.09.2021 - Ivanildo Bacelar - Historico de atendimento - fim    -->

          <!-- 12.11.2021 - Icaro Rodrigues - Busca Atendimento - Inicio  -->

          <li
            class="nav-item"
            role="presentation"
            v-if="stateLocal.funcaoBuscaAtendimento"
          >
            <a
              class="nav-link"
              id="busca-history-tab"
              data-toggle="tab"
              href="#busca-history"
              @click="setBusca"
              role="modal"
              aria-controls="busca-history"
              aria-selected="false"
              :title="tm('buscar')"
            >
              <svg
                class="hw-20 text-muted"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </a>
          </li>

          <!-- 12.11.2021 - Icaro Rodrigues - Busca Atendimento - fim  -->

          <li v-if="!isEmbedded" class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="quick-settings-tab"
              data-toggle="tab"
              href="#quick-settings"
              @click="setSettings"
              role="tab"
              aria-controls="quick-settings"
              aria-selected="false"
              :title="tm('configuracoes')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="30"
                width="30"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </a>
          </li>

          <!-- 25.11.2021 - Ricardison Rios - Alteração de Senha do Usuário - Início -->
          <li v-if="!isEmbedded" class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="quick-settings-tab"
              data-toggle="tab"
              href="#quick-settings"
              role="tab"
              aria-controls="quick-settings"
              aria-selected="false"
              :title="tm('alteraSenha')"
              @click="setProfilePassword"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="30"
                width="30"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </a>
          </li>
          <!-- 25.11.2021 - Ricardison Rios - Alteração de Senha do Usuário - Fim -->
        </ul>
        <!-- Appbar Nav End -->
      </div>
      <!-- Tab panes -->
      <div
        :class="
          store.state.appBar != ''
            ? 'tab-content appnavbar-content appnavbar-content-visible ' +
              (store.state.appBar === mutationsTypes.ATENDIMENTO ? 'vw-50' : '') +
              (store.state.appBar === mutationsTypes.HISTORICO ? 'vw-50' : '')
            : 'tab-content appnavbar-content'
        "
      >
        <div class="tab-pane h-100 active" id="app-welcome" role="tabpanel">
          <div class="appnavbar-content-wrapper">
            <div
              class="d-flex flex-column justify-content-center text-center h-100 w-100"
            >
              <div class="container">
                <div class="avatar avatar-lg mb-2">
                  <img
                    height="45"
                    width="45"
                    src="./../assets/images/icon-user.png"
                    style="border-radius: 50% !important"
                    alt=""
                  />
                </div>
                <h5>{{ tm("ola") + nomeUsuario }}!</h5>
                <p class="text-muted">{{ tm("mensagemApp01") }}</p>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="
            store.state.appBar === mutationsTypes.SETTINGS
              ? 'tab-pane h-100 active'
              : 'tab-pane h-100'
          "
          id="quick-settings"
          role="tabpanel"
          aria-labelledby="quick-settings-tab"
        >
          <div class="appnavbar-content-wrapper">
            <div class="appnavbar-scrollable-wrapper">
              <div class="appnavbar-heading sticky-top">
                <ul class="nav justify-content-between align-items-center">
                  <!-- Sidebar Title Start -->
                  <li class="text-center">
                    <h5 class="text-truncate mb-0">
                      {{ tm("configuracoes") }}
                    </h5>
                  </li>
                  <!-- Sidebar Title End -->
                  <!-- Close Sidebar Start -->
                  <li class="nav-item list-inline-item">
                    <a href="javascript:void(0)" data-appcontent-close="">
                      <!-- Default :: Inline SVG -->
                      <svg
                        class="hw-22"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        @click="appBarClear"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                      <!-- Alternate :: External File link -->
                      <!-- <img class="injectable hw-22" src="./../assets/media/heroicons/outline/x.svg" alt=""> -->
                    </a>
                  </li>
                  <!-- Close Sidebar End -->
                </ul>
              </div>
              <div class="appnavbar-body">
                <div class="settings-container">
                  <div class v-if="!isEmbedded">
                    <div class="card-header">
                      <p class="m-0">{{ tm("tema") }}</p>
                    </div>
                    <div class="card-body p-1">
                      <ul class="list-group border list-group-flush">
                        <li class="list-group-item py-2">
                          <div class="media align-items-center">
                            <div class="media-body">
                              <p class="mb-0">{{ tm("escuro") }}</p>
                            </div>
                            <div class="custom-control custom-switch ml-2">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="quickSettingSwitch1"
                                v-model="temaEscuro"
                              />
                              <label
                                class="custom-control-label"
                                for="quickSettingSwitch1"
                                >&nbsp;</label
                              >
                            </div>
                          </div>
                        </li>

                        <li v-if="false" class="list-group-item py-2">
                          <div class="media align-items-center">
                            <div class="media-body">
                              <p class="mb-0">Mutant</p>
                            </div>
                            <div class="custom-control custom-switch ml-2">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="quickSettingSwitch2"
                                v-model="temaMutant"
                              />
                              <label
                                class="custom-control-label"
                                for="quickSettingSwitch2"
                                >&nbsp;</label
                              >
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class v-if="!isEmbedded">
                    <div class="card-header">
                      <p class="m-0">{{ tm("linguagem") }}</p>
                    </div>
                    <div class="card-body p-1">
                      <button class="btn m-0" type="button" @click="changeLang('pt')">
                        <flag iso="BR" />
                      </button>
                      <!--<button class="btn  m-0"  type="button"  @click="changeLang('en')">
              <flag iso="US" />
            </button>
            -->
                      <button class="btn m-0" type="button" @click="changeLang('es')">
                        <flag iso="ES" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 14.09.2021 - Ivanildo Bacelar - Historico de atendimento - início-->
        <div
          id="chat-history"
          :class="
            store.state.appBar === mutationsTypes.HISTORICO
              ? 'tab-pane h-100 active'
              : 'tab-pane h-100'
          "
          role="tabpanel"
          aria-labelledby="chat-history-tab"
        >
          <div class="appnavbar-content-wrapper">
            <div class="appnavbar-scrollable-wrapper">
              <div class="appnavbar-heading sticky-top">
                <ul class="nav justify-content-between align-items-center">
                  <!-- Sidebar Title Start -->
                  <li class="text-center">
                    <h5 class="text-truncate mb-0">
                      {{ tm("historicoAtendimento") }}
                    </h5>
                  </li>
                  <!-- Sidebar Title End -->
                  <!-- Close Sidebar Start -->
                  <li class="nav-item list-inline-item">
                    <a href="javascript:void(0)" data-appcontent-close="">
                      <!-- Default :: Inline SVG -->
                      <svg
                        class="hw-22"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        @click="appBarClear"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                      <!-- Alternate :: External File link -->
                      <!-- <img class="injectable hw-22" src="./../assets/media/heroicons/outline/x.svg" alt=""> -->
                    </a>
                  </li>
                  <!-- Close Sidebar End -->
                </ul>
              </div>
              <historico ref="historicoCmp" />
            </div>
          </div>
        </div>
        <div
          v-if="atendimentoAvailable"
          :class="
            store.state.appBar === mutationsTypes.ATENDIMENTO
              ? 'tab-pane h-100 active'
              : 'tab-pane h-100'
          "
          id="atendimento"
          role="tabpanel"
          aria-labelledby="atendimento-tab"
        >
          <div class="appnavbar-heading sticky-top">
            <ul class="nav justify-content-between align-items-center">
              <!-- Sidebar Title Start -->
              <li class="text-center">
                <h5 class="text-truncate mb-0">
                  {{ tm("atendimentoHumano") }}
                </h5>
              </li>
              <!-- Sidebar Title End -->
              <!-- Close Sidebar Start -->
              <li class="nav-item list-inline-item">
                <a href="javascript:void(0)" data-appcontent-close="">
                  <!-- Default :: Inline SVG -->
                  <svg
                    class="hw-22"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    @click="appBarClear"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                  <!-- Alternate :: External File link -->
                  <!-- <img class="injectable hw-22" src="./../assets/media/heroicons/outline/x.svg" alt=""> -->
                </a>
              </li>
              <!-- Close Sidebar End -->
            </ul>
          </div>
          <atendimento
            @close="appBarClear"
            @enviarCatalogo="sendCatalog"
            @enviarDescricaoProduto="sendProductDescription"
            @enviarMsgChat="sendMsgChat"
            ref="atendimentoCmp"
          />
        </div>
        <div
          v-if="clienteAvailable"
          :class="
            store.state.appBar === mutationsTypes.CLIENTE
              ? 'tab-pane h-100 active'
              : 'tab-pane h-100'
          "
          id="cliente"
          role="tabpanel"
          aria-labelledby="cliente-tab"
        >
          <div class="appnavbar-content-wrapper">
            <div class="appnavbar-scrollable-wrapper">
              <div class="appnavbar-heading sticky-top">
                <ul class="nav justify-content-between align-items-center">
                  <!-- Sidebar Title Start -->
                  <li class="text-center">
                    <h5 class="text-truncate mb-0">{{ tm("crmCliente") }}</h5>
                  </li>
                  <!-- Sidebar Title End -->
                  <!-- Close Sidebar Start -->
                  <li class="nav-item list-inline-item">
                    <a href="javascript:void(0)" data-appcontent-close="">
                      <!-- Default :: Inline SVG -->
                      <svg
                        class="hw-22"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        @click="appBarClear"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                      <!-- Alternate :: External File link -->
                      <!-- <img class="injectable hw-22" src="./../assets/media/heroicons/outline/x.svg" alt=""> -->
                    </a>
                  </li>
                  <!-- Close Sidebar End -->
                </ul>
              </div>
              <cliente autocomplete="false" @close="appBarClear" ref="clienteCmp" />
            </div>
          </div>
        </div>
        <div
          v-if="agendaAvailable"
          :class="
            store.state.appBar === mutationsTypes.AGENDA
              ? 'tab-pane h-100 active'
              : 'tab-pane h-100'
          "
          id="agenda"
          role="tabpanel"
          aria-labelledby="agenda-tab"
        >
          <div class="appnavbar-content-wrapper">
            <div class="appnavbar-scrollable-wrapper">
              <div class="appnavbar-heading sticky-top">
                <ul class="nav justify-content-between align-items-center">
                  <!-- Sidebar Title Start -->
                  <li class="text-center">
                    <h5 class="text-truncate mb-0">{{ tm("agenda") }}</h5>
                  </li>
                  <!-- Sidebar Title End -->
                  <!-- Close Sidebar Start -->
                  <li class="nav-item list-inline-item">
                    <a href="javascript:void(0)" data-appcontent-close="">
                      <!-- Default :: Inline SVG -->
                      <svg
                        class="hw-22"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        @click="appBarClear"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                      <!-- Alternate :: External File link -->
                      <!-- <img class="injectable hw-22" src="./../assets/media/heroicons/outline/x.svg" alt=""> -->
                    </a>
                  </li>
                  <!-- Close Sidebar End -->
                </ul>
              </div>
              <agenda
                :timezoneDefault="timezoneDefault"
                @close="appBarClear"
                @getChats="getChats"
                ref="agendaCmp"
              />
            </div>
          </div>
        </div>
        <!-- 30.03.2022 - Ana Ribeiro - Marketplace - inicio  -->
        <div
          v-if="marketplaceAvailable || pluginMarketplace"
          :class="
            store.state.appBar === mutationsTypes.MARKETPLACE
              ? 'tab-pane h-100 active'
              : 'tab-pane h-100'
          "
          id="marketplace"
          role="tabpanel"
          aria-labelledby="marketplace-tab"
        >
          <div class="appnavbar-content-wrapper">
            <div class="appnavbar-scrollable-wrapper">
              <div class="appnavbar-heading sticky-top">
                <ul class="nav justify-content-between align-items-center">
                  <!-- Sidebar Title Start -->
                  <li class="text-center">
                    <h5 class="text-truncate mb-0">{{ tm("buscaPedido") }}</h5>
                  </li>
                  <!-- Sidebar Title End -->
                  <!-- Close Sidebar Start -->
                  <li class="nav-item list-inline-item">
                    <a href="javascript:void(0)" data-appcontent-close="">
                      <!-- Default :: Inline SVG -->
                      <svg
                        class="hw-22"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        @click="appBarClear"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                      <!-- Alternate :: External File link -->
                      <!-- <img class="injectable hw-22" src="./../assets/media/heroicons/outline/x.svg" alt=""> -->
                    </a>
                  </li>
                  <!-- Close Sidebar End -->
                </ul>
              </div>
              <marketplace
                :timezoneDefault="timezoneDefault"
                @close="appBarClear"
                @getChats="getChats"
                ref="marketplaceCmp"
              />
            </div>
          </div>
        </div>
        <!-- 30.03.2022 - Ana Ribeiro - Marketplace - fim  -->
      </div>
    </div>
    <!-- App Add-ons End -->
    <div
      :class="store.state.appBar != '' ? 'backdrop backdrop-visible' : 'backdrop'"
      @click="appBarClear"
    ></div>
    <!-- All Modals Start -->
    <!-- Modal :: LOGOUT-->
    <div class="modal fade" id="logout" tabindex="-1" role="dialog" aria-hidden="true">
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-zoom"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Logout</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-2 hide-scrollbar">
            <div class="row">
              <div class="col-12">
                <h6 class="text-truncate">{{ tm("perguntaLogout") }}</h6>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary js-btn-step"
              data-dismiss="modal"
            >
              {{ tm("nao") }}
            </button>
            <button
              type="button"
              class="btn btn-primary js-btn-step"
              data-dismiss="modal"
              @click="logout"
            >
              {{ tm("sim") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal :: Minhas Campanhas-->
    <div
      class="modal fade"
      id="minhasCampanhas"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-zoom"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ tm("minhasCampanhas") }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0 hide-scrollbar">
            <div class="row">
              <div class="col-12">
                <ul class="list-group list-group-flush">
                  <li
                    v-for="campanha in store.state.minhasCampanhas"
                    :key="campanha.id"
                    class="list-group-item"
                  >
                    <div class="media">
                      <h6 class="text-truncate">{{ campanha.nome }}</h6>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal :: Enviar HSM-->
    <div
      class="modal fade"
      ref="modalHsm"
      id="envarHsm"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-zoom"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ tm("enviar") + " HSM" }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-2 hide-scrollbar">
            <div class="row form-group">
              <div class="col-12">
                <select
                  class="custom-select font-size-sm shadow-none"
                  v-model="hsmSelected"
                >
                  <option value="">{{ tm("selecioneHsm") }}</option>
                  <option
                    v-for="mensagemHsm in listHSM"
                    :key="mensagemHsm.mensagemSalva.id"
                    :value="mensagemHsm.mensagemSalva"
                  >
                    {{ mensagemHsm.mensagemSalva.titulo }}
                  </option>
                </select>
              </div>
            </div>
            <div v-if="hsmSelected" class="row form-group">
              <div class="col-12">
                <span>{{ tm("mensagemPadrao") + hsmSelected.mensagem }}</span>
              </div>
            </div>
            <div v-if="hsmSelected" class="row">
              <label class="col-5">{{ tm("numeroWhatsapp") }}</label>
              <div class="col-7">
                <input
                  type="number"
                  class="form-control form-control-md"
                  v-model="selectedWhatsApp"
                />
              </div>
            </div>
            <div v-if="selectedParams.length > 0">
              <div v-for="param in selectedParams" :key="param.chave" class="row">
                <label class="col-5">{{ param.chave }}:</label>
                <div class="col-7">
                  <input
                    type="text"
                    class="form-control form-control-md"
                    v-model="param.valor"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary js-btn-step"
              data-dismiss="modal"
            >
              {{ tm("cancelar") }}
            </button>
            <button
              type="button"
              class="btn btn-primary js-btn-step"
              :disabled="!hsmSelected"
              @click="sendHsm"
            >
              {{ tm("enviar") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal :: Enviar Imagem-->
    <div
      class="modal fade"
      id="enviarImagem"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-zoom"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ tm("enviar") + " " + tm("imagem") }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-2 hide-scrollbar">
            <div class="row">
              <div class="col-12">
                <div v-if="!fileAnexo">
                  <h6>
                    {{
                      tm("formatosAceitos") +
                      " " +
                      Validar.DEFAULT_PROPS_FILES.allowed_image_types.toString()
                    }}
                  </h6>
                  <h6>{{ tm("limiteArquivo") + " 5Mb" }}</h6>
                  <label for="inputImage" class="btn btn-primary js-btn-step">
                    + {{ tm("arquivo") }}</label
                  >
                  <input
                    id="inputImage"
                    style="display: none"
                    name="inputImage"
                    type="file"
                    @change="onFileChange"
                    accept="image/jpeg,image/jpg,image/png,image/bmp"
                  />
                </div>
                <div v-else>
                  <h6>{{ tm("arquivo") }}: {{ fileSelected.name }}</h6>
                  <img :src="fileAnexo" class="w-100 h-100" />
                  <a :title="tm('remover')" href="#" @click="clearFile">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      width="24"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary js-btn-step"
              data-dismiss="modal"
            >
              {{ tm("cancelar") }}
            </button>
            <button
              type="button"
              class="btn btn-primary js-btn-step"
              data-dismiss="modal"
              :disabled="!fileAnexo"
              @click="sendAnexo('inputImage')"
            >
              {{ tm("enviar") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal :: Enviar Audio-->
    <div
      class="modal fade"
      id="enviarAudio"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-zoom"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ tm("enviar") + " " + tm("audio") }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-2 hide-scrollbar">
            <div class="row">
              <div class="col-12">
                <div v-if="!fileAnexo">
                  <h6>
                    {{
                      tm("formatosAceitos") +
                      " " +
                      Validar.DEFAULT_PROPS_FILES.allowed_audio_types.toString()
                    }}
                  </h6>
                  <h6>{{ tm("limiteArquivo") + " 5Mb" }}</h6>
                  <label for="inputAudio" class="btn btn-primary js-btn-step">
                    + {{ tm("arquivo") }}</label
                  >
                  <input
                    id="inputAudio"
                    style="display: none"
                    name="inputAudio"
                    type="file"
                    @change="onFileChange"
                    accept="audio/mpeg,audio/mp4,audio/aac,audio/ogg,audio/m4a,audio/gsm,audio/x-gsm, .gsm"
                  />
                </div>
                <div v-else>
                  <h6>{{ tm("arquivo") }} : {{ fileSelected.name }}</h6>
                  <audio controls="x" :src="fileAnexo" style="max-width: 90%">
                    Your browser does not support the audio element.
                  </audio>
                  <a :title="tm('remover')" href="#" @click="clearFile">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      width="24"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary js-btn-step"
              data-dismiss="modal"
            >
              {{ tm("cancelar") }}
            </button>
            <button
              type="button"
              class="btn btn-primary js-btn-step"
              data-dismiss="modal"
              :disabled="!fileAnexo"
              @click="sendAnexo('inputAudio')"
            >
              {{ tm("enviar") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal :: Enviar Video-->
    <div
      class="modal fade"
      id="enviarVideo"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-zoom"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ tm("enviar") + " " + tm("video") }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-2 hide-scrollbar">
            <div class="row">
              <div class="col-12">
                <div v-if="!fileAnexo">
                  <h6>
                    {{
                      tm("formatosAceitos") +
                      " " +
                      Validar.DEFAULT_PROPS_FILES.allowed_video_types.toString()
                    }}
                  </h6>
                  <h6>{{ tm("limiteArquivo") + " 5Mb" }}</h6>
                  <label for="inputVideo" class="btn btn-primary js-btn-step">
                    + {{ tm("arquivo") }}</label
                  >
                  <input
                    id="inputVideo"
                    style="display: none"
                    name="inputVideo"
                    type="file"
                    @change="onFileChange"
                    accept="video/mp4"
                  />
                </div>
                <div v-else>
                  <h6>{{ tm("arquivo") }}: {{ fileSelected.name }}</h6>
                  <video controls="x" :src="fileAnexo" style="max-width: 90%">
                    Your browser does not support the video element.
                  </video>
                  <a :title="tm('remover')" href="#" @click="clearFile">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      width="24"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary js-btn-step"
              data-dismiss="modal"
            >
              {{ tm("cancelar") }}
            </button>
            <button
              type="button"
              class="btn btn-primary js-btn-step"
              data-dismiss="modal"
              :disabled="!fileAnexo"
              @click="sendAnexo('inputVideo')"
            >
              {{ tm("enviar") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal :: Enviar Documento-->
    <div
      class="modal fade"
      id="enviarDocumento"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-zoom"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ tm("enviar") + " " + tm("documento") }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-2 hide-scrollbar">
            <div class="row">
              <div class="col-12">
                <div v-if="!fileAnexo">
                  <h6>
                    {{
                      tm("formatosAceitos") +
                      " " +
                      Validar.DEFAULT_PROPS_FILES.allowed_document_types.toString()
                    }}
                  </h6>
                  <h6>{{ tm("limiteArquivo") + " 5Mb" }}</h6>
                  <label for="inputDocument" class="btn btn-primary js-btn-step">
                    + {{ tm("arquivo") }}</label
                  >
                  <input
                    id="inputDocument"
                    style="display: none"
                    name="inputDocument"
                    type="file"
                    @change="onFileChange"
                    accept="application/pdf,application/msword,.docx,application/vnd.ms-excel,.xlsx, application/vnd.ms-powerpoint, application/zip"
                  />
                </div>
                <div v-else>
                  <h6>Arquivo: {{ fileSelected.name }}</h6>
                  <a :title="tm('remover')" href="#" @click="clearFile">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      width="24"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary js-btn-step"
              data-dismiss="modal"
            >
              {{ tm("cancelar") }}
            </button>
            <button
              type="button"
              class="btn btn-primary js-btn-step"
              data-dismiss="modal"
              :disabled="!fileAnexo"
              @click="sendAnexo('inputDocument')"
            >
              {{ tm("enviar") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal :: Transferir Atendimento-->
    <div
      class="modal fade"
      ref="modalTransferir"
      id="transferir"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-zoom"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ tm("transferirAtendimento") }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-2 hide-scrollbar">
            <div v-if="!isEmbedded" class="row form-group">
              <div class="col-12">
                <input type="datetime-local" v-model="dataAgendamentoTicket" />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-12">
                <select
                  class="custom-select font-size-sm shadow-none"
                  v-model="campanhaTransfSelected"
                >
                  <option value="">{{ tm("selecioneCampanha") }}</option>
                  <option
                    v-for="campanha in listCampanhasAtivas"
                    :key="campanha.nome"
                    :value="campanha"
                  >
                    {{ campanha.nome }}
                  </option>
                </select>
              </div>
            </div>
            <div
              v-if="listAgentes.length > 0 && campanhaTransfSelected.loginAtivo"
              class="row form-group"
            >
              <div class="col-12">
                <select
                  class="custom-select font-size-sm shadow-none"
                  v-model="agenteSelected"
                >
                  <option value="0">{{ tm("selecioneAgente") }}</option>
                  <option
                    v-for="agente in listAgentes"
                    :key="agente.id"
                    :value="agente.id"
                  >
                    {{ agente.nome }}
                  </option>
                </select>
              </div>
            </div>
            <div v-if="listMotivoTransf.length > 0" class="row form-group">
              <div class="col-12">
                <select
                  class="custom-select font-size-sm shadow-none"
                  v-model="motivoSelected"
                >
                  <option :value="null">{{ tm("selecioneMotivo") }}</option>
                  <option
                    v-for="motivo in listMotivoTransf"
                    :key="motivo.id"
                    :value="motivo"
                  >
                    {{ motivo.nome }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary js-btn-step"
              data-dismiss="modal"
            >
              {{ tm("cancelar") }}
            </button>
            <button type="button" class="btn btn-primary js-btn-step" @click="transferir">
              {{ tm("transferir") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal"
      ref="modalSair"
      id="idModalSair"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-zoom"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ tm("atendimentoFinalizadoCliente") }}
            </h5>
            <button type="button" class="close" @click="fecharModal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-2 hide-scrollbar">
            <div class="row form-group" v-for="obj in objFimAtendimento" :key="obj.numID">
              <div v-if="!!obj.show" class="col-12">
                <p>
                  <b>{{ tm("telefone") }}/ID</b>:{{ obj.numID }}
                </p>
                <p>
                  <b>{{ tm("data") + "/" + tm("hora") }}</b
                  >: {{ obj.dataHora }}
                </p>
                <p>
                  <b>{{ tm("canal") }}</b
                  >:{{ obj.canal }}
                </p>
                <p>
                  <b>{{ tm("campanha") }}</b
                  >:{{ obj.motivo }}
                </p>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary js-btn-step"
              @click="fecharModal"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal neew-->

    <!-- Modal neew-->
    <!-- Modal :: Mensagem Rápida-->
    <div
      class="modal fade"
      ref="modalMsgRapida"
      id="msgRapida"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-zoom"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ tm("mensagemRapida") }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-2 hide-scrollbar">
            <div class="row form-group">
              <div class="col-12">
                <select
                  class="custom-select font-size-sm shadow-none"
                  v-model="topicoSelected"
                >
                  <option value="0">
                    {{ tm("selecioneTopicoMsgRapida") }}
                  </option>
                  <option
                    v-for="topico in listTopicos"
                    :key="topico.id"
                    :value="topico.id"
                  >
                    {{ topico.nome }}
                  </option>
                </select>
              </div>
            </div>
            <div v-if="listMsgRapidas.length > 0" class="row form-group">
              <div class="col-12">
                <select
                  class="custom-select font-size-sm shadow-none"
                  v-model="msgRapidaSelected"
                >
                  <option value="">{{ tm("selecioneMsgRapida") }}</option>
                  <option
                    v-for="msg in listMsgRapidas"
                    :key="msg.id"
                    :value="msg.mensagem"
                    :title="msg.mensagem"
                  >
                    {{ msg.mensagem.substring(0, 60) + "..." }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary js-btn-step"
              data-dismiss="modal"
            >
              {{ tm("cancelar") }}
            </button>
            <button
              type="button"
              class="btn btn-primary js-btn-step"
              data-dismiss="modal"
              :disabled="listMsgRapidas.length == 0"
              @click="enviarMsgRapida"
            >
              {{ tm("selecionar") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal :: Puxar Atendimento-->
    <div
      class="modal fade"
      ref="modalPuxarAtendimento"
      id="puxarAtendimento"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-zoom"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="campanhaSelecionada" class="modal-title">
              {{ tm("puxarAtendimento") }}
            </h5>
            <h5 v-if="!campanhaSelecionada" class="modal-title">
              {{ tm("puxarAtendimentoAleatorio") }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-2 hide-scrollbar">
            <div class="row">
              <div class="col-12">
                <h6 v-if="campanhaSelecionada" class="text-truncate">
                  {{ tm("confirmaPuxarCampanha") }} '{{ campanhaSelecionada.nome }}'?
                </h6>
                <h6 v-if="!campanhaSelecionada" class="text-truncate">
                  {{ tm("confirmaPuxarAleatorio") }}
                </h6>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary js-btn-step"
              data-dismiss="modal"
            >
              {{ tm("nao") }}
            </button>
            <button
              type="button"
              class="btn btn-primary js-btn-step"
              data-dismiss="modal"
              @click="puxarAtendimento()"
            >
              {{ tm("sim") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal :: Gerar Atendimento -->
    <div
      class="modal fade"
      ref="modalNovoAtendimento"
      id="novoAtendimento"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-zoom modal-sm"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ tm("gerarAtendimento") }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body p-2 hide-scrollbar">
            <div class="row">
              <div class="col-12 form-group mb-3 pl-3">
                <label class="mb-1">{{ tm("numeroWhatsapp") }} *</label>
                <input
                  type="text"
                  ref="inputTel"
                  placeholder="+99 (99) 99999-9999"
                  id="inputTel"
                  required
                  v-maska="['+## (##) #####-####', '+## (##) #####-####']"
                  class="form-control p-1"
                  v-model="telefoneAtendimento"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary js-btn-step"
              data-dismiss="modal"
            >
              {{ tm("cancelar") }}
            </button>
            <button
              type="button"
              class="btn btn-primary js-btn-step"
              data-dismiss="modal"
              @click.prevent="gerarAtendimento()"
            >
              {{ tm("gerar") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal :: Gerar Atendimento - FIM -->
    <!-- Modal :: Finalizar Atendimento-->
    <div
      class="modal fade"
      ref="modalFinalizarAtendimento"
      id="finalizarAtendimento"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-zoom"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ tm("finalizarAtendimento") }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-2 hide-scrollbar">
            <h6 v-if="listTabulacoes.length == 0" class="modal-title">
              {{ tm("efetuarFimAtendimento") }}
            </h6>
            <div class="row" v-if="listTabulacoes.length > 0">
              <div class="col-12">
                <h6 class="text-truncate">{{ tm("selecioneFinalizacao") }}</h6>
                <div class="form-group">
                  <select
                    class="custom-select font-size-sm shadow-none"
                    v-model="tabulacaoSelected"
                  >
                    <option v-for="tab in listTabulacoes" :key="tab.id" :value="tab.id">
                      {{ tab.nome }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row" v-if="listTabulacoes2.length > 0">
              <div class="col-12">
                <div class="form-group">
                  <select
                    class="custom-select font-size-sm shadow-none"
                    v-model="tabulacaoSelected2"
                  >
                    <option v-for="tab in listTabulacoes2" :key="tab.id" :value="tab.id">
                      {{ tab.nome }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary js-btn-step"
              data-dismiss="modal"
            >
              {{ tm("cancelar") }}
            </button>
            <button
              type="button"
              class="btn btn-primary js-btn-step"
              data-dismiss="modal"
              @click.prevent="finalizarAtendimento(selectedChatQueue)"
              :disabled="
                listTabulacoes.length == 0
                  ? false
                  : tabulacaoSelected == 0
                  ? true
                  : listTabulacoes2.length > 0 && tabulacaoSelected2 == 0
                  ? true
                  : false
              "
            >
              {{ tm("finalizar") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 14.09.2021 - Ivanildo Bacelar - Historico de atendimento - fim   -->

    <modal
      class="buscaAtendimento"
      :resizable="true"
      :adaptive="true"
      :draggable="true"
      v-if="stateLocal.showModalSearchHistoryMessages"
      @closeModal="stateLocal.showModalSearchHistoryMessages = false"
    >
      <template v-slot:body>
        <busca-historico
          @close-busca="stateLocal.showModalSearchHistoryMessages = false"
        />
      </template>

      <template v-slot:footer>
        <div></div>
      </template>
    </modal>

    <!-- 12.11.2021 - Icaro Rodrigues - Busca Atendimento - fim  -->

    <!-- 25.11.2021 - Ricardison Rios - Alteração da Senha do Usuário - Fim -->
    <modal
      v-if="stateLocal.profilePassword"
      :title="tm('alteraSenha')"
      @closeModal="stateLocal.profilePassword = false"
    >
      <template v-slot:body>
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label for="current-password">{{ tm("senhaAtual") }}</label>
              <input
                type="password"
                class="form-control form-control-md"
                id="current-password"
                :placeholder="tm('senhaAtual')"
                autocomplete="off"
                v-model="stateLocal.current_password"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label for="new-password">{{ tm("novaSenha") }}</label>
              <input
                type="password"
                class="form-control form-control-md"
                id="new-password"
                :placeholder="tm('novaSenha')"
                autocomplete="off"
                v-model="stateLocal.new_password"
              />
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label for="repeat-password">{{ tm("repetirSenha") }}</label>
              <input
                type="password"
                class="form-control form-control-md"
                id="repeat-password"
                :placeholder="tm('repetirSenha')"
                autocomplete="off"
                v-model="stateLocal.confirm_password"
              />
            </div>
          </div>
        </div>
      </template>

      <template v-slot:footer class="card-footer d-flex justify-content-end">
        <div>
          <button
            type="button"
            class="btn btn-link text-muted mx-1"
            @click="clearFieldsPass"
          >
            {{ tm("limpar") }}
          </button>
          <button type="button" class="btn btn-primary" @click="saveChangesPass">
            {{ tm("salvar") }}
          </button>
        </div>
      </template>
    </modal>
    <!-- 25.11.2021 - Ricardison Rios - Alteração da Senha do Usuário - Fim -->

    <!-- All Modals End -->
  </div>
</template>
<script src="./Home.js">
</script>
<style scoped>
.avatar.avatar-online:before {
  background: red !important;
}

.mainBody {
  display: block;
}

.dateTime {
  font-size: 0.75rem;
  display: flex;
  color: #adb5bd;
  white-space: nowrap;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.item-center-left {
  position: relative;
  display: block;
  margin-left: auto;
}

.item-center-right {
  margin-right: auto;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 1.375rem;
  height: 1.935rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
.text-sla {
  color: red;
}
</style>
