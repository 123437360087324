import ApiBotService from '../../services/ApiBotService'
import ApiGPTService from '../../services/ApiGPTService'
import constants from '../../util/constants'
import moment from "moment";
import Validadores from '../../util/Validadores';
import { set } from 'date-fns';

const state = {
    apiBotHub: [],
    enderecosClientes: [],
    secoesLoja: [],
    selectedLoja: [],
    lojasCliente: [],
    carrinhoCliente: [],
    pedidos: [],
    pedidoFinalizado: [],
    selectedLojaDados: [],
    currentOpcao: 'secoes',
    isloadingSecoes: false,
    listaPedidos: [],
    verificaEstoque: false,
    existeSecoesLoja: [],
    chatLoader: false,
    chatMessages: [{
        role: "assistant",
        message: "Olá! Como posso te ajudar hoje?"
    }],
}
const mutations = {
    setApiBotHub(state, botHubs) {
        state.apiBotHub = botHubs
    },
    setEnderecosCliente(state, enderecos) {
        state.enderecosClientes = enderecos
    },
    setSecoesLoja(state, secoes) {
        state.secoesLoja = secoes
    },
    setLojaList(state, loja) {
        state.selectedLoja = loja
    },
    setLojaSelected(state, { loja, chatId }) {
        state.selectedLoja[chatId] = loja
    },
    setLojaDadosList(state, list) {
        state.selectedLojaDados = list
    },
    setLojaSelectedDados(state, { dados, chatId }) {
        state.selectedLojaDados[chatId] = dados
    },
    setLojasCliente(state, lojas) {
        state.lojasCliente = lojas
    },
    setCarrinhoList(state, list) {
        state.carrinhoCliente = list
    },
    setPedidos(state, pedidos) {
        state.pedidos = pedidos
    },
    setListaPedidos(state, listaPedidos) {
        state.listaPedidos = listaPedidos
    },
    setPedidoFinalizado(state, pedido) {
        state.pedidoFinalizado = pedido
    },
    setOpcao(state, opcao) {
        state.currentOpcao = opcao
    },
    setVerificaEstoque(state, value) {
        state.verificaEstoque = value
    },

    setChatLoader(state, isLoading) {
        state.chatLoader = isLoading;
    },
    setGptResponse(state, response) {
        state.chatMessages.push({
            role: "assistant",
            message: response
        });
    },
    setGptRequest(state, payload) {
        state.chatMessages.push({
            role: payload.role,
            message: payload.message
        });
    },
}

const actions = {

    async getAdressesCliente(context) {
        let chatId = context.rootState.selectedChatQueue.chatId
        let token = context.state.apiBotHub[chatId] ? context.state.apiBotHub[chatId].token : null
        if (token) {
            try {
                const response = await ApiBotService.GetClientAdressesBot(token)
                context.state.enderecosClientes[chatId] = response.data.addresses
            } catch (error) {

            }
        }
    },

    async getSessoesLoja(context) {
        try {
            context.state.isloadingSecoes = true
            let chatId = context.rootState.selectedChatQueue.chatId
            let idLoja = context.state.selectedLoja[chatId]
            let token = context.state.apiBotHub[chatId] ? context.state.apiBotHub[chatId].token : null
            let idChannel = 2
            context.state.existeSecoesLoja[idLoja] = null
            if (token) {
                const response = await ApiBotService.GetSessionsFromStore(token, idLoja, { q: null, idChannel: idChannel });
                if (response.data.sessions.length > 0) {
                    context.state.existeSecoesLoja[idLoja] = true
                } else {
                    context.state.existeSecoesLoja[idLoja] = null
                }
                context.state.secoesLoja[idLoja] = response.data.sessions.sort(function(a, b) {
                    if (a.sessionName < b.sessionName) return -1;
                    if (a.sessionName > b.sessionName) return 1;
                    return 0;
                });
            }
        } catch (error) {
            console.debug(error)
        } finally {
            context.state.isloadingSecoes = false
        }

    },

    async getLojasCliente(context) {
        let chatId = context.rootState.selectedChatQueue.chatId
        let token = context.state.apiBotHub[chatId] ? context.state.apiBotHub[chatId].token : null
        if (token && !context.state.lojasCliente[chatId]) {
            context.state.lojasCliente[chatId] = []
            let list = context.state.enderecosClientes[chatId]
            for (let i = 0; i < list.length; i++) {
                try {
                    let idrede = process.env.VUE_APP_COD_REDE ? process.env.VUE_APP_COD_REDE : constants.ID_REDE
                    const response = await ApiBotService.GetStoresByRede(token, idrede, { addressId: list[i].idAddress });
                    for (let j = 0; j < response.data.length; j++) {
                        if (!context.state.lojasCliente[chatId].some(x => x.restaurantId == response.data[j].restaurantId)) {
                            context.state.lojasCliente[chatId].push(response.data[j])
                        }
                    }
                } catch (error) {}
            }
        }
    },

    async getPedidoClienteOrder(context, idPedido) {
        let chatId = context.rootState.selectedChatQueue.chatId
        let idLoja = context.state.selectedLoja[chatId]
        let token = context.state.apiBotHub[chatId] ? context.state.apiBotHub[chatId].token : null
        if (token && idLoja) {
            try {

                const response = await ApiBotService.GetPedidoAbandono(token, idPedido)
                var linkPagamento = '';
                try {
                    var responseListaDados = await ApiBotService.GetDadosAdicionaisPedido(token, idPedido);
                    for (var i of responseListaDados.data.order_Addendum_List_Results) {
                        if (i.addendumKey == "LINKPAGAMENTO") {
                            linkPagamento = i.addendumValue;
                        }
                    }
                } catch (errorL) {
                    console.log('Erro Link pagamento pedido:', errorL)
                }
                // context.state.listaPedidos[chatId] = response.data
                let lPedidos = response.data
                let produtos = lPedidos.productOrderEnteredList
                let produto = {}
                let pedido = {
                    id: lPedidos.idOrder,
                    dataHora: moment(lPedidos.orderDateTime).format('DD/MM/YYYY hh:mm'),
                    valorFinal: lPedidos.totalAmountOrder,
                    metodoPagamento: lPedidos.paymentMethodDescription,
                    linkPagamento: linkPagamento
                }
                pedido.itens = [];
                for (let [i, prod] of produtos.entries()) {
                    produto = {
                        itemDesc: prod.productDescription,
                        itemValor: prod.totalProductValue,
                        itemQuantidade: prod.productQuantity,
                        itemId: prod.idProduct,
                        itemIdOrder: prod.idProductOrder,
                        itemCodigo: prod.productCode,
                        itemurl: prod.productURLImage
                    }
                    pedido.itens.push(produto)
                }
                context.state.listaPedidos[chatId] = pedido
            } catch (error) {
                //console.log(error)
            }
        }
    },

    async getPedidoCliente(context) {
        let chatId = context.rootState.selectedChatQueue.chatId
        let idLoja = context.state.selectedLoja[chatId]
        let token = context.state.apiBotHub[chatId] ? context.state.apiBotHub[chatId].token : null
        if (token && idLoja) {
            try {
                const response = await ApiBotService.GetPedido(token, idLoja)
                let produtos = response.data.preOrder_List_Results
                if (produtos.length > 0) {
                    context.state.pedidos[chatId] = response.data.idPreOrder
                    let produto = {}
                    for (let [i, prod] of produtos.entries()) {
                        //console.log("pre order- produto:", prod)
                        produto = {
                            id: prod.cod_Produto_int,
                            sku: prod.cod_Produto_Lojista_var,
                            desc: prod.nome_Produto_var + " " +
                            (prod.nome_Item_Produto_var ?  prod.nome_Item_Produto_var + " ": "") +
                            (prod.nome_SubItem_Produto_var ?  prod.nome_SubItem_Produto_var : ""),
                           // desc: (prod.desc_SubItem_Produto_var ?
                           //     prod.desc_SubItem_Produto_var : (prod.nome_Item_Produto_var ?
                           //         prod.nome_Item_Produto_var : prod.nome_Produto_var)),
                            idItem: prod.cod_Item_Produto_Loja_int,
                            idSubItem: prod.cod_SubItem_Produto_int,
                            valor: prod.valor_PreOrdem_num,
                            qtd: prod.qtd_PreOrdem_int,
                            desconto: prod.valor_Desconto_Produto_num ? prod.valor_Desconto_Produto_num : 0,
                            estoque: 0
                        }

                        produto.estoque = await context.dispatch('getEstoque', { produto: produto, chatId: chatId, idLoja: idLoja })

                        await context.dispatch('addProdutoCarrinho', { produto: produto, chatId: chatId, preOrder: false })
                    }

                }
            } catch (error) {
                try {
                    const response = await ApiBotService.GetPedidoByWebSession(token, idLoja, context.rootState.selectedChatQueue.chatUUID)
                    context.state.pedidos[chatId] = response.data.idPreOrder
                    let produtos = response.data.preOrder_List_Results
                    if (produtos.length > 0) {
                        let produto = {}
                        for (let [i, prod] of produtos.entries()) {
                            produto = {
                                id: prod.cod_Produto_int,
                                sku: prod.cod_Produto_Lojista_var,
                                desc: prod.nome_Produto_var + " " +
                                (prod.nome_Item_Produto_var ?  prod.nome_Item_Produto_var + " ": "") +
                                (prod.nome_SubItem_Produto_var ?  prod.nome_SubItem_Produto_var : ""),
                                // desc: (prod.desc_SubItem_Produto_var ?
                                //     prod.desc_SubItem_Produto_var : (prod.nome_Item_Produto_var ?
                                //         prod.nome_Item_Produto_var : prod.nome_Produto_var)),
                                idItem: prod.cod_Item_Produto_Loja_int,
                                idSubItem: prod.cod_SubItem_Produto_int,
                                valor: prod.valor_PreOrdem_num,
                                qtd: prod.qtd_PreOrdem_int,
                                desconto: prod.valor_Desconto_Produto_num ? prod.valor_Desconto_Produto_num : 0,
                                estoque: 0
                            }

                            produto.estoque = await context.dispatch('getEstoque', { produto: produto, chatId: chatId, idLoja: idLoja })
                            await context.dispatch('addProdutoCarrinho', { produto: produto, chatId: chatId, preOrder: false })
                        }
                    }
                } catch (error) {
                    //alert(error)
                    //console.log(error)
                }
            }
        }
    },

    async addProdutoCarrinho(context, param) {

        let idLoja = context.state.selectedLoja[param.chatId]
        //console.log('add carrinho:preorder-produto', param.preOrder, param.produto)
        if (param.preOrder) {
            var idChannel = context.rootState.selectedChatQueue.customVariables['@idChannel']
            const response = await ApiBotService.AddProduto(
                context.state.apiBotHub[param.chatId].token, {
                    idClient: context.state.apiBotHub[param.chatId].idClient,
                    storeId: idLoja,
                    value: param.produto.valor,
                    quantity: param.produto.qtd,
                    deliveryFee: 0,
                    idRetailer: 0,
                    idProduct: param.produto.id,
                    idProductItem: param.produto.idItem,
                    idProductSubitem: param.produto.idSubItem
                },
                context.rootState.selectedChatQueue.chatUUID,
                idChannel
            )
            context.state.pedidos[param.chatId] = response.data.idPreOrder

        }
        if (!context.state.carrinhoCliente[param.chatId]) {
            let produtos = []
            produtos.push(param.produto)
            context.state.carrinhoCliente[param.chatId] = produtos
        } else {
            let ind = context.state.carrinhoCliente[param.chatId].findIndex(
                x => x.id === param.produto.id && x.idItem === param.produto.idItem && x.idSubItem === param.produto.idSubItem)
            if (ind < 0) {
                context.state.carrinhoCliente[param.chatId].push(param.produto)
            } else {
                context.state.carrinhoCliente[param.chatId][ind].qtd++
            }
        }
    },
    async deleteProdutoCarrinho(context, param) {
        return await ApiBotService.DeleteProduto(
            context.state.apiBotHub[param.chatId].token,
            context.state.pedidos[param.chatId], {
                idProduct: param.produto.id,
                idProductItem: param.produto.idItem,
                idProductSubitem: param.produto.idSubItem,
            })
    },

    async getEstoque(context, param) {
        let token = context.state.apiBotHub[param.chatId].token
        let qtd = 0
        try {
            const response = await ApiBotService.GetProduto(
                token,
                param.idLoja,
                param.produto.id, {
                    itemProductId: param.produto.idItem === 0 ? null : param.produto.idItem,
                    subitemProductId: param.produto.idSubItem === 0 ? null : param.produto.idSubItem
                })
            if (response.data.itemProductValues.length === 0) {
                qtd = response.data.qtdProdutoEstoque
            } else if (response.data.itemProductValues[0].subItemProductValues.length === 0) {
                qtd = response.data.qtdItemEstoque

            } else {
                qtd = response.data.itemProductValues[0].subItemProductValues[0].qtdSubItemEstoque
            }
        } catch (error) {} finally {
            return qtd
        }
    },

    async changeLoja(context, param) {
        let chatId = context.rootState.selectedChatQueue.chatId
        let idLoja = context.state.selectedLoja[chatId]
        let token = context.state.apiBotHub[chatId].token
        if (token && idLoja && context.state.carrinhoCliente[chatId] && context.state.carrinhoCliente[chatId].length > 0) {
            const response = await ApiBotService.DeletePedido(token, param.idLoja)
            context.state.pedidos[chatId] = 0
            context.state.carrinhoCliente[chatId] = []
            let produtos = param.carrinho
            let produto = {}
            for (let [i, prod] of produtos.entries()) {
                produto = {
                    id: prod.id,
                    sku: prod.sku,
                    desc: prod.desc,
                    idItem: prod.idItem,
                    idSubItem: prod.idSubItem,
                    valor: prod.valor,
                    qtd: prod.qtd,
                    estoque: prod.estoque,
                    desconto: prod.desconto
                }
                produto.estoque = await context.dispatch('getEstoque', { produto: produto, chatId: chatId, idLoja: idLoja })
                produto.qtd = (produto.qtd <= produto.estoque ? produto.qtd : produto.estoque)
                context.dispatch('addProdutoCarrinho', { produto: produto, chatId: chatId, preOrder: true })
            }

        }
    },

    async finalizarPedido(context, param) {

        let idPreOrder = context.state.pedidos[param.chatId]
        let token = context.state.apiBotHub[param.chatId].token
        let end = context.state.enderecosClientes[param.chatId]
        if (end == undefined && param.dadosCliente != null) {
            end = param.dadosCliente.crmEnderecoCliente && param.dadosCliente.crmEnderecoCliente.length > 0 ? param.dadosCliente.crmEnderecoCliente : null
        }
        if (token && context.state.carrinhoCliente[param.chatId] && end) {
            const response = await ApiBotService.FinalizarPedido(
                token,
                idPreOrder, {
                    idAddress: end[0].idAddress ? end[0].idAddress : end[0].codEnderecoZappag,
                    idPaymentMethod: param.idPaymentMethod,
                    requestedCashChange: 0,
                    cPF_CNPJ_Invoice: param.isNota ? param.cpfNota : '',
                    nameInInvoice: '',
                    noteOrder: '',
                    paymentMode: param.paymentMode,
                    idUserATH: param.idUserATH,
                    idCampaign: param.idCampaign,
                    idExternalOrder: "",
                    idRetailer: context.rootState.selectedChatQueue.customVariables['@idRevendedor']
                })
            context.state.pedidoFinalizado[param.chatId] = response.data;
            context.rootState.selectedChatQueue.customVariables['@codPedido'] = response.data.idOrder

            if (param.cpfTerceiro) {
                const respCpf = await ApiBotService.AddOrderAddendum(
                    token,
                    response.data.idOrder, {
                        addendumKey: 'terceiro.cpf',
                        addendumValue: param.cpfTerceiro
                    })
            }
            if (param.terceiro) {
                const respNome = await ApiBotService.AddOrderAddendum(
                    token,
                    response.data.idOrder, {
                        addendumKey: 'terceiro.nome',
                        addendumValue: param.terceiro
                    })
            }
        }
    },

    async clearCompra(context, chatId) {
        context.state.carrinhoCliente[chatId] = []
        context.state.pedidos[chatId] = null
        context.state.pedidoFinalizado[chatId] = null
    },

    async getLojasClienteDefault(context) {
        let chatId = context.rootState.selectedChatQueue.chatId
        let token = context.state.apiBotHub[chatId] ? context.state.apiBotHub[chatId].token : null
        let idLoja = context.state.selectedLoja[chatId]
        if (token && !context.state.lojasCliente[chatId]) {
            context.state.lojasCliente[chatId] = []
            try {
                const response = await ApiBotService.GetLoja(token, idLoja)
                context.state.lojasCliente[chatId].push(response.data)
            } catch (error) {}
        }
    },

    async getLoja(context, id) {
        let chatId = context.rootState.selectedChatQueue.chatId
        let token = context.state.apiBotHub[chatId] ? context.state.apiBotHub[chatId].token : null
        if (token) {
            try {
                const response = await ApiBotService.GetLoja(token, id)
                return response.data
            } catch (error) {}
        }
        return {}
    },

    clearCountProduct(context, chatId) {
        let countProduct = localStorage.getItem('countProduct') ? JSON.parse(localStorage.getItem("countProduct")) : []
            //window.location.reload(true)
        if (countProduct.length > 0) {
            let ind = countProduct.findIndex(x => x.chave == chatId)
            if (ind != -1) {
                countProduct.splice(ind, 1)
                localStorage.setItem("countProduct", JSON.stringify(countProduct))
            }
        }
    },

    async incrementCountProduct(context, chatId) {
        let countProduct = localStorage.getItem('countProduct') ? JSON.parse(localStorage.getItem("countProduct")) : []
        let qtd = 1
        if (countProduct.length > 0) {
            let ind = countProduct.findIndex(x => x.chave == chatId)
            if (ind != -1) {
                countProduct[ind].valor++
                    qtd = countProduct[ind].valor
                localStorage.setItem("countProduct", JSON.stringify(countProduct))
                return qtd
            }
        }
        let item = { chave: chatId, valor: qtd }
        countProduct.push(item)
        localStorage.setItem("countProduct", JSON.stringify(countProduct))
        return qtd
    },

    async integrationGPT({commit}, message) {
        try {
            commit('setChatLoader', true)
            const response = await ApiGPTService.sendMessage(message)

            if (response.status === 200) {
                commit('setChatLoader', false)
                commit('setGptResponse', response.data.choices[0].message.content)
            }

        } catch (error) {
            console.log(error)
        }
    }

}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
