<template>
  <div class="carrinho">
    <div
      class="scrolling"
      style="overflow: auto; height: 55.5vh; width: 100%; margin-top: 5px"
    >
      <table class="table table-sm">
        <thead class="thead-light">
          <tr>
            <th colspan="2" style="text-align: center">
              <p class="text-truncate mb-0">{{ tm("produto") }}</p>
            </th>
            <th style="text-align: center">
              <p class="text-truncate mb-0">{{ tm("quantidade") }}</p>
            </th>
            <th style="text-align: center">
              <p class="text-truncate mb-0">{{ tm("valor") }}</p>
            </th>
            <th style="text-align: center">
              <p class="text-truncate mb-0">{{ tm("acoes") }}</p>
            </th>
          </tr>
        </thead>
        <tr v-for="(produto, index) in carrinhoCliente" :key="produto.id">
          <td>
            <p class="text-truncate mb-0">{{ produto.sku }}</p>
          </td>
          <td>
            <p class="text-truncate mb-0">{{ getDescricaoProduto(produto) }}</p>
          </td>
          <td style="text-align: center">
            <p class="text-truncate mb-0">{{ produto.qtd }}</p>
          </td>
          <td style="text-align: center">
            <p class="text-truncate mb-0">
              {{ formataMoeda(produto.valor * produto.qtd) }}
            </p>
          </td>
          <td style="text-align: center">
            <a
              class="py-1"
              href="#"
              :title="tm('removerItemCarrinho')"
              role="button"
              @click="excluirProduto(produto, index)"
            >
              <i class="fas fa-trash-alt fa-lg"></i>
            </a>
          </td>
        </tr>
        <tfoot class="thead-light">
          <tr>
            <th colspan="3" style="text-align: center">
              <p class="text-truncate mb-0">{{ tm("total") }}</p>
            </th>
            <th style="text-align: center">
              <p class="text-truncate mb-0">{{ totalCarrinho }}</p>
            </th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="modal-footer">
      <button
        v-if="!stateLocal.isEmbedded"
        type="button"
        class="btn btn-primary js-btn-step pr-1 pl-1"
        :disabled="
          stateLocal.isLoading ||
          stateLocal.isLoadingBot ||
          !carrinhoCliente ||
          carrinhoCliente.length == 0
        "
        @click="finalizarPedidoBot"
      >
        {{ tm("finalizarBot") }}
        <div
          v-if="stateLocal.isLoadingBot"
          class="spinner-border spinner-border-sm"
          role="status"
        ></div>
      </button>
      <button
        type="button"
        class="btn btn-primary js-btn-step pr-1 pl-1"
        :disabled="
          stateLocal.isLoading ||
          stateLocal.isLoadingBot ||
          !carrinhoCliente ||
          carrinhoCliente.length == 0
        "
        @click="finalizarPedidoAth"
      >
        {{ tm("finalizarAth") }}
      </button>
    </div>

    <modal
      v-if="stateLocal.showModalCompra"
      :title="tm('finalizarCompra')"
      @closeModal="cancelarCompra"
    >
      <template v-slot:body>
        <div v-if="stateLocal.inicioCompra">
          <div class="row" v-if="stateLocal.temCarrinho == true && fonteVtex">
            <div class="col-12 bg-light">
              <h6 class="text-truncate">
                <b>{{ tm("participarCampanha") }}</b>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="participarCampanha"
                    v-model="stateLocal.participarCampanha"
                    @click="confirmaCampanha"
                  />
                  <label
                    class="custom-control-label mb-0"
                    for="participarCampanha"
                  ></label>
                </div>
              </h6>
              <h6 class="text-wrap">
                <b>{{ nomeCampanhaUsuario }}</b>
              </h6>
            </div>
          </div>
          <div v-if="fonteVtex">
            <div class="row">
              <div class="col-5">
                <h6 class="text-truncate">
                  <b>{{ tm("cpfNota") }}:</b>
                </h6>
              </div>
              <div class="col-7">
                <h6 class="text-truncate">
                  <b>{{ tm("formaPagamento") }}:</b>
                </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-5 bg-light">
                <div class="media align-items-center">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="nota"
                      v-model="stateLocal.nota"
                    />
                    <label class="custom-control-label mb-0" for="nota"></label>
                  </div>
                  <div class="media-body">
                    <p class="mb-0">{{ tm("utiliarCpfNota") }}</p>
                  </div>
                </div>
                <div class="input-group input-group-sm pl-0">
                  <input
                    id="cpfnota"
                    placeholder="999.999.999-99"
                    v-maska="'###.###.###-##'"
                    class="form-control mb-1"
                    style="text-align: center"
                    :disabled="!stateLocal.nota"
                    v-model="stateLocal.cpfNota"
                  />
                </div>
              </div>
              <div class="col-7 bg-light">
                <ul class="list-group border list-group-flush">
                  <li
                    class="list-group-item py-1"
                    v-for="pagamento in stateLocal.formasPagamento"
                    :key="pagamento.idPaymentMethod"
                  >
                    <div class="media align-items-center">
                      <div class="custom-control custom-switch">
                        <input
                          type="radio"
                          class="custom-control-input"
                          :value="pagamento.idPaymentMethod"
                          :id="pagamento.idPaymentMethod"
                          v-model="stateLocal.pagamento"
                        />
                        <label
                          class="custom-control-label"
                          :for="pagamento.idPaymentMethod"
                        ></label>
                      </div>
                      <div class="media-body">
                        <p class="mb-0">
                          {{ pagamento.descriptionPaymentMethod }}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="row">
              <div class="col-12">
                <h6 class="text-truncate">
                  <b>{{ tm("cpfNota") }}:</b>
                </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-12 bg-light">
                <div class="media align-items-center">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="nota"
                      v-model="stateLocal.nota"
                    />
                    <label class="custom-control-label mb-0" for="nota"></label>
                  </div>
                  <div class="media-body">
                    <p class="mb-0">{{ tm("utiliarCpfNota") }}</p>
                  </div>
                </div>
                <div class="input-group input-group-sm pl-0">
                  <input
                    id="cpfnota"
                    placeholder="999.999.999-99"
                    v-maska="'###.###.###-##'"
                    class="form-control mb-1"
                    style="text-align: center"
                    :disabled="!stateLocal.nota"
                    v-model="stateLocal.cpfNota"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-1">
                <h6 class="text-wrap">
                  <b>{{ tm("endereco") }}</b>
                </h6>
                <h6 class="text-wrap"></h6>
              </div>
            </div>
            <div class="row">
              <div class="col-8 bg-light">
                <h6>{{ enderecoCliente.slice(0, -14) }}</h6>
              </div>
            </div>
            <div class="row">
              <br v-if="!stateLocal.isEmbedded" />
              <div class="media align-items-center">
                <div class="custom-control custom-switch ml-2">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="alterarCep"
                    v-model="stateLocal.alterarCep"
                  />
                  <label class="custom-control-label mb-0" for="alterarCep"></label>
                </div>
                <div class="media-body">
                  <p class="mb-0">{{ tm("alterarEndereco") }}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-9 bg-light mb-1">
                <div class="input-group input-group-sm pl-0">
                  <input
                    id="alteracep"
                    placeholder="99999-999"
                    v-maska="'#####-###'"
                    class="form-control"
                    style="text-align: center"
                    :disabled="!stateLocal.alterarCep"
                    v-model="stateLocal.cep"
                  />
                </div>
              </div>
              <div class="col-3 bg-light">
                <button
                  type="button"
                  class="btn btn-primary js-btn-step"
                  :disabled="!stateLocal.alterarCep || !stateLocal.cep || stateLocal.isloadingEndereco"
                  @click="buscaEndereco()"
                >
                  <div><i class="fas fa-search"></i>
                  <div
                  v-if="stateLocal.isloadingEndereco"
                  class="spinner-border spinner-border-sm"
                  role="status"
                ></div>
                </div>
                </button>
              </div>
            </div>
          <div
            class="col-12 bg-light scrolling"
           v-if="stateLocal.enderecoBusca.viaCep"
            style="overflow: auto; height: 20vh; width: 100%; margin-top: 5px"
          >
            <div  v-if="stateLocal.enderecoBusca.viaCep" class="row">

              <div  class="col-9 bg-light">

                <div class="input-group input-group-sm pl-0">
                  <input
                    placeholder="Endereco"
                    id="enderecoLogradouro"
                    class="form-control mb-1"
                    v-model="stateLocal.enderecoBusca.viaCep.logradouro"
                  />
                </div>
                  <div class="input-group input-group-sm pl-0">
                 <input
                    placeholder="Bairro"
                    id="enderecoBairro"
                    class="form-control mb-1"
                    v-model="stateLocal.enderecoBusca.viaCep.bairro"
                  />
                </div>
                  <div class="input-group input-group-sm pl-0">

                 <input
                    placeholder="Cidade"
                    id="endereco"
                    class="form-control mb-1 mr-1"
                    v-model="stateLocal.enderecoBusca.viaCep.localidade"
                  />

                 <input
                    placeholder="Estado"
                    id="enderecoEstado"
                    class="form-control mb-1 col-3"
                    v-model="stateLocal.enderecoBusca.viaCep.uf"
                  />
                </div>
                <div class="input-group input-group-sm pl-0">
                  <input
                    placeholder="nº"
                    id="numeroend"
                    class="form-control mb-1 col-3 mr-1"
                    v-model="stateLocal.numEndereco"
                  />

                  <input
                    placeholder="complemento"
                    id="complemento"
                    class="form-control mb-1"
                    v-model="stateLocal.complementoEnd"
                  />
                </div>
              </div>
              <div class="col-3 bg-light d-flex align-items-end">
                <div>
                  <button
                    v-if="stateLocal.enderecoBusca.viaCep"
                    type="button"
                    class="btn btn-primary js-btn-step mb-1"
                    :disabled="!stateLocal.alterarCep || stateLocal.isloadingMetodo"
                    @click="buscaEnderecoOK()"
                  >
                    <div><i class="fas fa-check"></i>
                     <div
                     v-if="stateLocal.isloadingMetodo"
                  class="spinner-border spinner-border-sm"
                  role="status"
                ></div></div>
                  </button>
                </div>
              </div>
            </div>
            </div>
          </div>
             <div  v-if="!fonteVtex" class="row">
              <div class="col-12 mt-2">
                <h6 class="text-nowrap">
                  <b>{{ tm("metodosEntrega") }}:</b>
                </h6>
              </div>
            </div>
          <div
            class="col-12 bg-light scrolling"
            v-if="!fonteVtex"
            style="overflow: auto; height: 17vh; width: 100%; margin-top: 5px"
          >
            <div class="row mt-1">
            <h6  v-if="stateLocal.metodosEntrega.length > 0"><b>{{ tm("frete") }}:</b></h6>
            </div>
            <div class="row" v-for="metodo in stateLocal.metodosEntrega" :key="metodo.id">
              <div class="custom-control custom-switch">
                <input
                  type="radio"
                  class="custom-control-input"
                  :value="metodo.id"
                  :id="metodo.id"
                  v-model="stateLocal.entrega"
                  @click="tipoMetodo(metodo)"
                  required
                />
                <label class="custom-control-label" :for="metodo.id"></label>
              </div>
              <div class="col-7 bg-light" v-if="metodo.shippingEstimate.slice(-1) != 'h'">
                <h6>
                  {{
                    metodo.name +
                    " - Receba em até " +
                    metodo.shippingEstimate.slice(0, -2) +
                    (metodo.shippingEstimate.slice(0, -2) == "1" ? " dia útil" : " dias úteis")
                  }}
                </h6>
              </div>
              <div class="col-7 bg-light" v-else>
                <h6>
                  {{
                    metodo.name +
                    " - Receba em até " +
                    metodo.shippingEstimate.slice(0, -1) +
                    (metodo.shippingEstimate.slice(0, -1) == "1" ? " hora": " horas")
                  }}
                </h6>
              </div>
              <div class="col-4 bg-light">
                <h6>{{ "Valor:" + formataMoeda(metodo.price / 100.0) }}</h6>
              </div>
            </div>
             <div class="row mt-1">
            <h6  v-if="stateLocal.retirarLoja.length > 0"><b>{{ tm("retireLoja") }}:</b></h6>
            </div>
            <div class="row" v-for="metodo in stateLocal.retirarLoja" :key="metodo.id">

              <div class="custom-control custom-switch">
                <input
                  type="radio"
                  class="custom-control-input"
                  :value="metodo.id"
                  :id="metodo.id"
                  v-model="stateLocal.entrega"
                  @click="tipoMetodo(metodo)"
                  required
                />
                <label class="custom-control-label" :for="metodo.id"></label>
              </div>
              <div class="col-7 bg-light" v-if="metodo.shippingEstimate.slice(-1) != 'h'">
                <h6>
                  {{
                    metodo.pickupStoreInfo.friendlyName +
                    " - Retire em até " +
                      metodo.shippingEstimate.slice(0, -2) +
                     (metodo.shippingEstimate.slice(0, -2) == "1" ? "dia útil" : " dias úteis")
                  }}
                </h6>
                <h6>
                  {{
                    metodo.pickupStoreInfo.address.street +
                    " - nº " +
                    metodo.pickupStoreInfo.address.number +
                    " -" +
                    metodo.pickupStoreInfo.address.state
                  }}
                </h6>
              </div>
              <div class="col-7 bg-light" v-else>
                <h6>
                  {{
                    metodo.pickupStoreInfo.friendlyName +
                    " - Retire em até " +
                    metodo.shippingEstimate.slice(0, -1) +
                    (metodo.shippingEstimate.slice(0, -1) == "1" ? " hora": " horas")
                  }}
                </h6>
                <h6>
                  {{
                    metodo.pickupStoreInfo.address.street +
                    " - nº " +
                    metodo.pickupStoreInfo.address.number +
                    " -" +
                    metodo.pickupStoreInfo.address.state
                  }}
                </h6>
              </div>
              <div class="col-4 bg-light">
                <h6>
                  {{ "Distancia:" + metodo.pickupDistance.toFixed(2) + " Km" }}
                </h6>
              </div>
            </div>
          </div>
          <div class="row" v-if="fonteVtex">
            <div class="col-12 mt-3">
              <h6 class="text-nowrap">
                <b>{{ tm("retiradaTerceiro") }}:</b>
              </h6>
            </div>
          </div>
          <div class="row" v-if="fonteVtex">
            <div class="col-3 bg-light">
              <h6 class="text-nowrap">{{ tm("nome") }}:</h6>
            </div>
            <div class="col-9 bg-light input-group input-group-sm mb-1">
              <input
                type="text"
                id="nometerceiro"
                class="form-control"
                v-model="stateLocal.terceiro"
                onkeypress="return alpha(event)"
              />
            </div>
          </div>

          <div class="row" v-if="fonteVtex">
            <div class="col-3 bg-light">
              <h6 class="text-wrap">{{ tm("cpf") }}:</h6>
            </div>
            <div class="col-6 bg-light input-group input-group-sm mb-0">
              <input
                id="cpfterceiro"
                placeholder="999.999.999-99"
                v-maska="'###.###.###-##'"
                class="form-control"
                style="text-align: center"
                v-model="stateLocal.cpfTerceiro"
              />
            </div>
            <div class="col-4 bg-light"></div>
          </div>
        </div>
        <div v-else>
          <div
            class="scrolling"
            style="overflow: auto; height: 60vh; width: 100%; margin-top: 5px"
          >
            <div class="row">
              <div class="col-12">
                <h6 class="text-truncate">
                  <b>{{ tm("produtos") }}</b>
                </h6>
              </div>
            </div>

            <div class="row" v-for="prod in carrinhoCliente" :key="prod.id">
              <div class="col-8 bg-light">
                <h6>{{ getDescricaoProduto(prod) }}</h6>
              </div>
              <div class="col-1 bg-light">
                <h6>{{ "x" + prod.qtd }}</h6>
              </div>
              <div class="col-3 bg-light">
                <h6>
                  {{ formataMoeda(prod.valor * prod.qtd - prod.desconto) }}
                </h6>
              </div>
            </div>

            <div class="row" v-if="fonteVtex">
              <div class="col-5">
                <h6 class="text-wrap">
                  <b>{{ tm("cpfNota") }}</b>
                </h6>
              </div>
              <div class="col-7">
                <h6 class="text-wrap">
                  <b>{{ tm("formaPagamento") }}</b>
                </h6>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-12">
                <h6 class="text-wrap">
                  <b>{{ tm("cpfNota") }}</b>
                </h6>
              </div>
            </div>
            <div class="row" v-if="fonteVtex">
              <div class="col-5 bg-light">
                <h6 v-if="stateLocal.nota" class="text-wrap">
                  {{ tm("utiliarCpfNota") }}
                </h6>
              </div>
              <div class="col-7 bg-light">
                <h6 class="text-wrap">{{ formaPagamento }}</h6>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-5 bg-light">
                <h6 v-if="stateLocal.nota" class="text-wrap">
                  {{ tm("utiliarCpfNota") }}
                </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-5 bg-light">
                <h6 v-if="stateLocal.nota" class="text-wrap">
                  {{ tm("cpf") }}:
                  {{
                    stateLocal.cpfNota ? Validar.formatCnpjCpf(stateLocal.cpfNota) : ""
                  }}
                </h6>
              </div>
              <div class="col-7 bg-light">
                <h6 class="text-truncate"></h6>
              </div>
            </div>
            <div v-if="stateLocal.delivery == false">
              <div class="row">
                <div class="col-12">
                  <h6 class="text-wrap">
                    <b>{{ tm("retireLoja") }}</b>
                  </h6>
                </div>
              </div>
            <div   v-if="fonteVtex" class="row">
              <div class="col-12 bg-light">
               <h6 class="text-wrap">{{ lojaCliente }}</h6>
              </div>
            </div>
              <div v-if="!fonteVtex" class="row">
                <div class="col-6 bg-light">
                  <h6 class="text-wrap">{{ stateLocal.metodoEntrega.pickupStoreInfo.friendlyName }}</h6>
                </div>
                <div class="col-6 bg-light">
                  <h6 class="text-wrap">
                    {{ stateLocal.metodoEntrega.pickupDistance.toFixed(2) + " Km" }}
                  </h6>
                </div>
              </div>
              <div  v-if="fonteVtex" class="row">
              <div class="col-12 bg-light">
                <h6>{{ "Endereço: " + enderecoLoja }}</h6>
            </div>
             </div>
               <div  v-if="!fonteVtex"  class="row">
              <div class="col-12 bg-light">
                  <h6>{{ "Endereço: " +
                     stateLocal.metodoEntrega.pickupStoreInfo.address.street +
                    " - nº " +
                     stateLocal.metodoEntrega.pickupStoreInfo.address.number +
                    " -" +
                     stateLocal.metodoEntrega.pickupStoreInfo.address.state
                   }}</h6>
              </div>
              </div>
            </div>
            <div v-else>
              <div class="row">
                <div class="col-12">
                  <h6 class="text-wrap">
                    <b>{{ tm("frete") }}</b>
                  </h6>
                </div>
              </div>
              <div class="row">
                <div class="col-12 bg-light">
                  <h6 class="text-wrap">
                    {{
                      stateLocal.metodoEntrega.id +
                      " - " +
                      "Receba em até " +
                      stateLocal.metodoEntrega.shippingEstimate.slice(0, -2) +
                      (stateLocal.metodoEntrega.shippingEstimate.slice(0, -2) =="1" ? " dia útil por ": " dias úteis por " )+
                      formataMoeda(stateLocal.metodoEntrega.price / 100.0)
                    }}
                  </h6>
                </div>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12">
                <h6 class="text-wrap">
                  <b>{{ tm("dadosCadastrais") }}</b>
                </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-12 bg-light">
                <h6 class="text-wrap">{{ nomeCLiente }}</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-12 bg-light">
                <h6>{{ "Endereço: " + enderecoCliente }}</h6>
              </div>
            </div>
            <div v-if="stateLocal.terceiro !== ''">
              <div class="row">
                <div class="col-12">
                  <h6 class="text-wrap">
                    <b>{{ tm("retiradaTerceiro") }}</b>
                  </h6>
                </div>
              </div>
              <div class="row">
                <div class="col-12 bg-light">
                  <h6>{{ tm("nome") }}: {{ stateLocal.terceiro }}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-12 bg-light">
                  <h6>
                    {{ tm("cpf") }}:
                    {{
                      stateLocal.cpfTerceiro
                        ? Validar.formatCnpjCpf(stateLocal.cpfTerceiro)
                        : ""
                    }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-secondary js-btn-step"
          :disabled="stateLocal.isLoadingCompra"
          @click="cancelarCompra"
        >
          {{ tm("cancelar") }}
        </button>
        <div v-if="stateLocal.inicioCompra">
          <button
            type="button"
            :disabled="stateLocal.validaEntrega"
            class="btn btn-primary js-btn-step"
            @click="confirmarTerceiro"
          >
            {{ tm("confirmar") }}
          </button>
        </div>
        <div v-else>
          <button
            type="button"
            class="btn btn-primary js-btn-step"
            :disabled="stateLocal.isLoadingCompra"
            @click="confimarPedidoAth"
          >
            {{ tm("confirmar") }}
            <div
              v-if="stateLocal.isLoadingCompra"
              class="spinner-border spinner-border-sm"
              role="status"
            ></div>
          </button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { reactive, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import Modal from "../../components/Modal";
import Validar from "../../util/Validadores";
import ApiBotService from "../../services/ApiBotService";
import ApiWebhookService from "../../services/ApiWebhookService";
import ChatService from "../../services/ChatService";
import EnderecoService from "../../services/EnderecoService";
import { useI18n } from "vue-i18n";
import { formatDistance } from "date-fns/esm";

export default {
  components: { Modal },
  setup(props, context) {
    const { tm } = useI18n();
    const store = useStore();
    const toast = useToast();
    const stateLocal = reactive({
      isLoading: false,
      isLoadingBot: false,
      isloadingMetodo:false,
      isloadingEndereco:false,
      showModalCompra: false,
      inicioCompra: true,
      idPreOrder: 0,
      idAddress: 0,
      zipCode: "0",
      temCarrinho: false,
      isLoadingCompra: false,
      formasPagamento: [],
      metodosEntrega: [],
      enderecoBusca: [],
      enderecoAlterar: [],
      cep: "",
      alterarCep: false,
      metodoEntrega: undefined,
      retirarLoja: [],
      pagamento: 7,
      entrega: 0,
      validaEntrega: false,
      delivery: false,
      participarCampanha: true,
      terceiro: "",
      cpfTerceiro: "",
      nota: false,
      bairro: "",
      numEndereco: "",
      token: "",
      logradouro: "",
      complementoEnd: "",
      loja: store.state.ath.selectedLojaDados[store.state.selectedChatQueue.chatId],
      endereco: {},
      cpfNota: "",
      isEmbedded: sessionStorage.getItem("isEmbedded")
        ? JSON.parse(sessionStorage.getItem("isEmbedded"))
        : false,
    });

    stateLocal.cpfNota = !stateLocal.isEmbedded
      ? store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId]
        ? Validar.formatCnpjCpf(
            store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId].cpf_Cnpj
          )
        : ""
      : store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]
      ? Validar.formatCnpjCpf(
          store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId].cpf
        )
      : "";

    const idClient = computed(() => {
      return store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId] &&
        store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]
          .codClienteZappag
        ? store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]
            .codClienteZappag
        : store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId]
        ? store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId].idClient
        : -1;
    });

    const campanhaUsuario = computed(() => {
      if (store.state.campanhaUsuario && store.state.campanhaUsuario.length > 0) {
        return store.state.campanhaUsuario[0].permite_Retirada_Terceiro_bit;
      }
      return "";
    });

    const nomeCampanhaUsuario = computed(() => {
      if (store.state.campanhaUsuario && store.state.campanhaUsuario.length > 0) {
        return store.state.campanhaUsuario[0].nome_Campanha_var;
      }
      return "";
    });

    const enterecoAtual = computed(() => {
      if (store.state.campanhaUsuario && store.state.campanhaUsuario.length > 0) {
        return store.state.campanhaUsuario[0].nome_Campanha_var;
      }
      return "";
    });
    const endZapppag = computed(() => {
      let end =
        store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]
          .crmEnderecoCliente;
      return end && end.length > 0 && !!end[0].codEnderecoZappag;
    });

    const carrinhoCliente = computed(() => {
      let carrinhoNew =
        store.state.ath.carrinhoCliente[store.state.selectedChatQueue.chatId];
      // if (carrinhoNew !== undefined) {
      // carrinhoNew.map(function (e) {
      //   console.log("map", e);
      //  if (e.sortimento !== null && e.sortimento !== undefined) {
      //      e.desc =   e.sortimento.productSubItemDescription;
      //   }
      // });
      //}
      return carrinhoNew;
    });

    const totalCarrinho = computed(() => {
      let arr = store.state.ath.carrinhoCliente[store.state.selectedChatQueue.chatId];
      let soma = 0;
      if (arr) {
        soma = arr.reduce(function (acumulador, el) {
          return acumulador + el.valor * el.qtd || 0;
        }, 0);
      }
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      }).format(soma);
    });

    const cpfCliente = computed(() => {
      return Validar.formatCnpjCpf(
        store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId].cpf_Cnpj
      );
    });

    const nomeCLiente = computed(() => {
      return !stateLocal.isEmbedded
        ? (store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId].nameClient
            ? store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId].nameClient
            : "") +
            " " +
            (store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId]
              .lastNameClient
              ? store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId]
                  .lastNameClient
              : "")
        : (store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId].nome
            ? store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId].nome
            : "") +
            " " +
            (store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]
              .sobrenome
              ? store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]
                  .sobrenome
              : "");
    });

    const celularCliente = computed(() => {
      return store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId]
        .cellphoneNumber;
    });

    const enderecoCliente = computed(() => {
      if (stateLocal.isEmbedded) {
        if (
          store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId] &&
          store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]
            .crmEnderecoCliente
        ) {
          let enderecoCliente =
            store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]
              .crmEnderecoCliente[0];
          if (stateLocal.enderecoAlterar.viaCep) {
            enderecoCliente.endereco = stateLocal.enderecoAlterar.viaCep.logradouro;
            enderecoCliente.numer = stateLocal.enderecoAlterar.viaCep.siafi;
            enderecoCliente.cep = stateLocal.cep;
            enderecoCliente.complemento = stateLocal.enderecoAlterar.viaCep.complemento;
          }
          return (
            (enderecoCliente.endereco ? enderecoCliente.endereco + ", " : "") +
            (enderecoCliente.numer ? enderecoCliente.numer + " - " : "") +
            (enderecoCliente.complemento ? enderecoCliente.complemento + ", " : "") +
            (enderecoCliente.bairro ? enderecoCliente.bairro + ", " : "") +
            (enderecoCliente.cidade ? enderecoCliente.cidade + " - " : "") +
            (enderecoCliente.estado ? enderecoCliente.estado : "") +
            (enderecoCliente.cep
              ? ". CEP: " + Validar.formatCEP(enderecoCliente.cep)
              : "")
          );
        }
      }
      if (stateLocal.endereco) {
        if (stateLocal.enderecoAlterar.viaCep) {
          stateLocal.endereco.addressName = stateLocal.enderecoAlterar.viaCep.logradouro;
          stateLocal.endereco.addressNumber = stateLocal.enderecoAlterar.viaCep.siafi;
          stateLocal.endereco.zipCode = stateLocal.cep;
          stateLocal.endereco.complement = stateLocal.enderecoAlterar.viaCep.complemento;
          stateLocal.endereco.state = stateLocal.enderecoAlterar.viaCep.uf;
          stateLocal.endereco.city = stateLocal.enderecoAlterar.viaCep.localidade;
        }
        return (
          (stateLocal.endereco.addressName
            ? stateLocal.endereco.addressName + ", "
            : "") +
          (stateLocal.endereco.addressNumber
            ? stateLocal.endereco.addressNumber + " - "
            : "") +
          (stateLocal.endereco.complement ? stateLocal.endereco.complement + ", " : "") +
          (stateLocal.endereco.city ? stateLocal.endereco.city + " - " : "") +
          (stateLocal.endereco.state ? stateLocal.endereco.state : "") +
          (stateLocal.endereco.zipCode ? ". CEP: " + stateLocal.endereco.zipCode : "")
        );
      } else {
        return "";
      }
    });

    const enderecoLoja = computed(() => {
      if (stateLocal.loja) {
        return stateLocal.loja.originAddress;
      } else {
        return "";
      }
    });
    const lojaCliente = computed(() => {
      if (stateLocal.loja) {
        return stateLocal.loja.restaurantName;
      } else {
        return "";
      }
    });

    const formaPagamento = computed(() => {
      let pag = stateLocal.formasPagamento.find(
        (x) => x.idPaymentMethod === stateLocal.pagamento
      );
      return pag ? pag.descriptionPaymentMethod || "" : "";
    });

    function confirmarTerceiro() {
      if (!stateLocal.formasPagamento || stateLocal.formasPagamento.length == 0) {
        toast.warning(tm("msgCarrinho01"));
        return;
      }
      if (!stateLocal.pagamento) {
        toast.error(tm("msgCarrinho02"));
        return;
      }
      let nometerc = stateLocal.terceiro.trim();
      let cpfterc = stateLocal.cpfTerceiro.trim();

      if (stateLocal.cpfNota && Validar.validarCPF(stateLocal.cpfNota.trim()) == false) {
        toast.error(tm("msgCarrinho03"));
        return;
      }

      if (nometerc.length > 0 && nometerc.split(" ").length < 2) {
        toast.error(tm("msgCarrinho04"));
        return;
      }
      if ((nometerc == "" && cpfterc != "") || (cpfterc == "" && nometerc != "")) {
        toast.error(tm("msgCarrinho05"));
      } else {
        if (Validar.validarSemCaractersEspecias(nometerc) == false) {
          toast.error(tm("msgCarrinho23"));
          return;
        }

        // if(nometerc.includes(" ")== false)
        // {
        //    toast.error("Necessario preencher ao menos um sobrenome");
        // }else
        if (cpfterc.length > 0 && cpfterc.length != 14) {
          toast.error(tm("msgCarrinho06"));
        } else {
          if (
            Validar.validarCPF(stateLocal.cpfTerceiro.trim()) == false &&
            cpfterc.length > 0
          ) {
            toast.error(tm("msgCarrinho07"));
            return;
          } else {
            stateLocal.inicioCompra = false;
          }
        }
      }
    }

    const getPaymentMethods = async () => {
      let chatId = store.state.selectedChatQueue.chatId;
      let idLoja = store.state.ath.selectedLoja[chatId];
      stateLocal.formasPagamento = [];
      if (store.state.ath.apiBotHub[chatId]) {
        let token = store.state.ath.apiBotHub[chatId].token;
        try {
          const response = await ApiBotService.GetPaymentMethods(token, idLoja);
          if (
            stateLocal.isEmbedded &&
            !store.state.usuario.empresa.gatewayPagamentoToken
          ) {
            var pagamentos = response.data.paymentMethods.filter(function (pag) {
              return pag.descriptionPaymentMethod != "SMB - Pagamento Online";
            });
            stateLocal.formasPagamento = pagamentos;
          } else {
            stateLocal.formasPagamento = response.data.paymentMethods;
          }
          let pag = !stateLocal.isEmbedded ? 7 : null;
          if (stateLocal.formasPagamento && stateLocal.formasPagamento.length == 1) {
            pag = stateLocal.formasPagamento[0].idPaymentMethod;
          }
          stateLocal.pagamento = pag;
        } catch (error) {}
      }
    };

    //   function getLoja(){
    //     let chatId = store.state.selectedChatQueue.chatId
    //     let idLoja = store.state.ath.selectedLoja[chatId]
    //     let token =  store.state.ath.apiBotHub[chatId].token
    //     ApiBotService.GetLoja(token,idLoja).then(response => {
    //       stateLocal.loja = response.data
    //     }).catch(error => {
    //       stateLocal.loja = {}
    //     })
    //  }

    function getEndereco() {
      if (store.state.ath.enderecosClientes[store.state.selectedChatQueue.chatId]) {
        stateLocal.endereco = store.state.ath.enderecosClientes[
          store.state.selectedChatQueue.chatId
        ].find((x) => x.preferentialAddress);
      }
    }

    function formataMoeda(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      }).format(value);
    }

    async function excluirProduto(value, ind) {
      try {
        await store.dispatch("ath/deleteProdutoCarrinho", {
          produto: value,
          chatId: store.state.selectedChatQueue.chatId,
        });
        store.state.ath.carrinhoCliente[store.state.selectedChatQueue.chatId].splice(
          ind,
          1
        );
        toast.info(tm("msgCarrinho08"));
      } catch (error) {
        toast.error(tm("msgCarrinho09"));
      }
    }

    async function finalizarPedidoBot() {
      if (!store.state.ath.carrinhoCliente[store.state.selectedChatQueue.chatId]) {
        toast.warning(tm("msgCarrinho10"));
        stateLocal.isLoadingBot = false;
      } else {
        if (stateLocal.isEmbedded && (!idClient.value || idClient.value == -1)) {
          toast.warning(tm("msgCarrinho11"));
          stateLocal.isLoadingBot = false;
        } else {
          if (stateLocal.isEmbedded && !endZapppag.value) {
            toast.warning(tm("msgCarrinho22"));
            stateLocal.isLoadingBot = false;
          } else {
            try {
              let chatId = store.state.selectedChatQueue.chatId;
              stateLocal.isLoadingBot = true;
              const response = await ChatService.FinalizarBot(
                store.state.selectedChatQueue.uuid,
                store.state.selectedChatQueue.chatId,
                store.state.ath.selectedLoja[store.state.selectedChatQueue.chatId],
                store.state.selectedChatQueue.customVariables["MediaId"],
                store.state.selectedChatQueue.customVariables["@idRevendedor"]
              );
              store.dispatch("ath/clearCountProduct", chatId);
              store.dispatch("clearMsgAlarme", chatId);
              await getChats();
              store.dispatch("ath/clearCompra", chatId);
              store.dispatch("clearIsNew", chatId);
              store.commit("setChatBody", null);
              store.commit("setHubConnection", null);
              store.commit("setChatMessages", []);
              store.commit("setChatMessagesOld", []);
              toast.info(tm("msgCarrinho12"));
            } catch (error) {
              console.debug(error);
              toast.error(tm("msgCarrinho13"));
            } finally {
              stateLocal.isLoadingCompra = false;
              context.emit("forceRerender", "carrinho");
            }
          }
        }
      }
    }

    async function finalizarPedidoAth() {
      if (!store.state.ath.carrinhoCliente[store.state.selectedChatQueue.chatId]) {
        toast.warning(tm("msgCarrinho10"));
      } else {
        if (stateLocal.isEmbedded && (!idClient.value || idClient.value == -1)) {
          toast.warning(tm("msgCarrinho11"));
        } else {
          if (stateLocal.isEmbedded && !endZapppag.value) {
            toast.warning(tm("msgCarrinho22"));
            stateLocal.isLoadingBot = false;
          } else {
            limparDados();
            store.state.ath.carrinhoCliente[store.state.selectedChatQueue.chatId] = [];
            let ped = await store.dispatch("ath/getPedidoCliente");
            let token =
              store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId].token;
            let idPreOrder =
              store.state.ath.pedidos[store.state.selectedChatQueue.chatId];
            if (!fonteVtex.value) {
              let idAddress = store.state.selectedChatQueue.customVariables["@idAddress"];
              let zipCode = store.state.selectedChatQueue.customVariables["@zipcode"];
              if (stateLocal.endereco && stateLocal.endereco.zipCode){
                idAddress = stateLocal.endereco.idAddress;
                zipCode = stateLocal.endereco.zipCode;
              }
              stateLocal.idAddress = idAddress;
              stateLocal.idPreOrder = idPreOrder;
              stateLocal.token = token;
              stateLocal.zipCode = zipCode;
               console.log("get GetMetodosEntrega",idAddress, zipCode);
              let responseEnd = await ApiBotService.GetMetodosEntrega(
                token,
                idPreOrder,
                idAddress,
                zipCode
              );
              stateLocal.metodosEntrega = responseEnd.data.deliverys;
              stateLocal.retirarLoja = responseEnd.data.withdrawals;
            }
            try {
              let cammpanha = store.state.campanhaUsuario[0].cod_Campanha_int;
              const TemitemCampanha = await ApiBotService.GetCamapanhaCarrinho(
                token,
                idPreOrder,
                cammpanha
              );
              //const TemitemCampanha = await ApiBotService.GetCamapanhaCarrinho(token, response.data.idPreOrder,cammpanha)
              stateLocal.temCarrinho = true;
            } catch (e) {
              stateLocal.participarCampanha = false;
            }
            // stateLocal.temCarrinho = true;
            stateLocal.inicioCompra = true;
            stateLocal.showModalCompra = true;
          }
        }
      }
    }

    function getResumoCompra() {
      let chatId =
        store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId].cellphoneNumber;
      let compras = store.state.ath.pedidoFinalizado[chatId].preOrder_Close_Results;
      let desconto = 0;
      for (let c of compras) {
        desconto = desconto + c.valor_Desconto_Produto_num;
      }
      let taxaEntrega = 0;
      if (stateLocal.metodoEntrega && stateLocal.metodoEntrega.price){
          taxaEntrega = stateLocal.metodoEntrega.price / 100.0;
      }
      if (chatId.startsWith("55")) {
        let resumo = "*" + tm("pedido") + ":*📝 \n";
        let produtos =
          store.state.ath.carrinhoCliente[store.state.selectedChatQueue.chatId] || [];

        let total = 0;

        for (let [i, prod] of produtos.entries()) {
          total = total + prod.valor * prod.qtd;
          // desconto = desconto + prod.desconto;
          resumo =
            resumo +
            `*${prod.qtd}` +
            "x" +
            getDescricaoProduto(prod) +
            //prod.desc +
            " - " +
            `${formataMoeda(prod.valor * prod.qtd)}*` +
            "\n";
        }
        let valorFinal = (total + taxaEntrega) - desconto;
        resumo =
          resumo + "\n*" + tm("msgCarrinho20") + ":* \n" + `${formataMoeda(taxaEntrega)}` + "\n";
        resumo =
          resumo +
          "\n*" +
          tm("valorTotal") +
          ":* 💰 \n" +
          `*${totalCarrinho.value}*` +
          "\n";
        resumo =
          resumo +
          "\n*" +
          tm("desconto") +
          ":* 💰 \n" +
          `*${formataMoeda(desconto)}*` +
          "\n";
        resumo =
          resumo +
          "\n*" +
          tm("valorFinal") +
          ":* 💰 \n" +
          `*${formataMoeda(valorFinal)}*` +
          "\n";
        if (
          stateLocal.terceiro != "" &&
          stateLocal.terceiro != null &&
          stateLocal.cpfTerceiro != "" &&
          stateLocal.cpfTerceiro != null
        ) {
          if (!fonteVtex.value) {
            resumo = resumo + "\n*" + "\n\n*" + tm("terceiroAutorizado") + ":* \n";
            resumo = resumo + tm("nome") + ":" + stateLocal.terceiro + "\n";
            resumo = resumo + tm("cpf") + ":" + stateLocal.cpfTerceiro + "\n";
          } else {
            resumo =
              resumo +
              "\n*" +
              tm("numeroPedido") +
              ":* 🔍 \n" +
              store.state.ath.pedidoFinalizado[store.state.selectedChatQueue.chatId]
                .idOrder +
              "\n\n*" +
              tm("terceiroAutorizado") +
              ":* \n";
            resumo = resumo + tm("nome") + ":" + stateLocal.terceiro + "\n";
            resumo = resumo + tm("cpf") + ":" + stateLocal.cpfTerceiro + "\n";
          }
        } else {
          if (fonteVtex.value) {
            resumo =
              resumo +
              "\n*" +
              tm("numeroPedido") +
              ":* 🔍 \n" +
              store.state.ath.pedidoFinalizado[store.state.selectedChatQueue.chatId]
                .idOrder;
          }
        }

        return resumo;
      } else {
        if (fonteVtex.value) {
          let resumo = tm("numeroPedido") + ": 📝\n";
        }
        let produtos =
          store.state.ath.carrinhoCliente[store.state.selectedChatQueue.chatId] || [];
        let desconto = 0;
        let total = 0;
        for (let [i, prod] of produtos.entries()) {
          desconto = desconto + prod.desconto;
          total = total + prod.valor * prod.qtd;
          resumo =
            resumo +
            `${prod.qtd}` +
            "x" +
            getDescricaoProduto(prod) +
           // prod.desc +
            " - " +
            `${formataMoeda(prod.valor * prod.qtd)}` +
            "\n";
        }
        let valorFinal = (total + taxaEntrega) - desconto;
        resumo =
          resumo + "\n" + tm("msgCarrinho20") + ": \n" + `${formataMoeda(taxaEntrega)}` +  "\n";
        resumo =
          resumo + "\n" + tm("valorTotal") + ": 💰 \n" + `${totalCarrinho.value}` + "\n";
        resumo =
          resumo + "\n" + tm("desconto") + ": 💰 \n" + `${formataMoeda(desconto)}` + "\n";
        resumo =
          resumo +
          "\n" +
          tm("valorFinal") +
          ": 💰 \n" +
          `${formataMoeda(valorFinal)}` +
          "\n";
        if (
          stateLocal.terceiro != "" &&
          stateLocal.terceiro != null &&
          stateLocal.cpfTerceiro != "" &&
          stateLocal.cpfTerceiro != null
        ) {
          if (fonteVtex.value) {
            resumo =
              resumo +
              "\n" +
              tm("numeroPedido") +
              ": 🔍 \n" +
              store.state.ath.pedidoFinalizado[store.state.selectedChatQueue.chatId]
                .idOrder +
              "\n\n" +
              tm("terceiroAutorizado") +
              ": \n";
            resumo = resumo + tm("nome") + ":" + stateLocal.terceiro + "\n";
            resumo = resumo + tm("cpf") + ":" + stateLocal.cpfTerceiro + "\n";
          } else {
            resumo = resumo + "\n\n" + tm("terceiroAutorizado") + ": \n";
            resumo = resumo + tm("nome") + ":" + stateLocal.terceiro + "\n";
            resumo = resumo + tm("cpf") + ":" + stateLocal.cpfTerceiro + "\n";
          }
        } else {
          if (!fonteVtex.value) {
            resumo =
              resumo +
              "\n" +
              tm("numeroPedido") +
              ": 🔍 \n" +
              store.state.ath.pedidoFinalizado[store.state.selectedChatQueue.chatId]
                .idOrder;
          }
        }

        return resumo;
      }
    }

    async function confimarPedidoAth() {
      stateLocal.isLoadingCompra = true;
      try {
        let idUserATH =
          store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId] &&
          store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]
            .codClienteZappag
            ? store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]
                .codClienteZappag
            : store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId]
            ? store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId].idClient
            : -1;
        let pag = stateLocal.formasPagamento.find(
          (x) => x.idPaymentMethod === stateLocal.pagamento
        );
        let debitCredit = pag ? pag.debitCredit : null;
        if (debitCredit) {
          debitCredit = debitCredit.trim();
        }
        let idCampanha = 0;
        if (stateLocal.participarCampanha === true) {
          idCampanha = store.state.campanhaUsuario[0].cod_Campanha_int;
        }
        if (!fonteVtex.value) {
          try {
            const responsePostEnd = await ApiBotService.PostMetodoEntregaEndereco(
              store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId].token,
              stateLocal.idPreOrder,
              stateLocal.idAddress,
              {
                itemIndex: 0,
                selectedDeliveryChannel: stateLocal.metodoEntrega.deliveryChannel,
                selectedSla: stateLocal.entrega,
              },
              {
                utmSource: store.state.selectedChatQueue.customVariables["@isRevendedor"]
                && store.state.selectedChatQueue.customVariables["@isRevendedor"].trim().toLowerCase()=="true"
                ? "ath-revendedor"
                : "ath-whatsapp"
              }
            );
          } catch(e){
            console.log("erro responsePostEnd", e)
          }
        }
        await store.dispatch("ath/finalizarPedido", {
          idCampaign: idCampanha,
          chatId: store.state.selectedChatQueue.chatId,
          idPaymentMethod: stateLocal.pagamento,
          paymentMode: !!debitCredit ? 2 : 1,
          cpfTerceiro: stateLocal.cpfTerceiro
            ? stateLocal.cpfTerceiro.replaceAll(".", "").replaceAll("-", "")
            : "",
          terceiro: stateLocal.terceiro,
          isNota: stateLocal.nota,
          idAddress: stateLocal.idAddress,
          cpfNota: stateLocal.cpfNota
            ? stateLocal.cpfNota.replaceAll(".", "").replaceAll("-", "")
            : "",
          idUserATH: stateLocal.isEmbedded ? idUserATH : store.state.usuario.id,
          dadosCliente: stateLocal.isEmbedded
            ? store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]
            : null,
        });
        if (store.state.ath.pedidoFinalizado[store.state.selectedChatQueue.chatId]) {
          let resumo = getResumoCompra();
          context.emit("enviarMsgChat", resumo);
          if (!!debitCredit) {
            let arr = {
              numeropedido:
                store.state.ath.pedidoFinalizado[store.state.selectedChatQueue.chatId]
                  .idOrder,
              telefoneCliente: store.state.selectedChatQueue.chatId,
            };
            if (stateLocal.isEmbedded) {
              arr.token = store.state.usuario.empresa.gatewayPagamentoToken;
            }
            let msgLink = "";
            if (fonteVtex.value) {
               const response = await ApiWebhookService.GetLink(arr);
               if (response.status === 200 || response.status === 201) {
                  msgLink = tm("msgCarrinho15") + response.data.link +  tm("msgCarrinho16");
                  context.emit("enviarMsgChat", msgLink);
                 } else {
                  toast.error(tm("msgCarrinho14"));
                }
            } else {
              let parte1MSgCheckout =
                "Excelente " +
                nomeCLiente.value +
                "! 😊 \n\nPara concluir a sua compra, basta acessar o link abaixo e fazer o pagamento👇🏽:\n\n";
              let parte2MsgCheckout =
                "\n\nÉ seguro e rápido! Assim que for concluído, você receberá a confirmação de compra no seu e-mail.\n\n Da nossa casa para sua casa.\n\n Agradecemos a preferência! \n\n Até mais! 💛💙";
              msgLink =
                store.state.ath.pedidoFinalizado[store.state.selectedChatQueue.chatId]
                  .externalShoppingCartUrl;
              context.emit(
                "enviarMsgChat",
                parte1MSgCheckout + msgLink + parte2MsgCheckout
              );
              }
          }
          store.dispatch("ath/clearCompra", store.state.selectedChatQueue.chatId);
          stateLocal.showModalCompra = false;
          stateLocal.inicioCompra = true;
          if (stateLocal.isEmbedded)  toast.info(tm("msgCarrinho17"));
        } else {
          toast.error(tm("msgCarrinho18"));
        }
      } catch (error) {
        console.log(error);
        toast.error(tm("msgCarrinho18"));
      } finally {
        stateLocal.isLoadingCompra = false;
        context.emit("forceRerender", "carrinho");
      }
    }

    function alpha(e) {
      var k;
      document.all ? (k = e.keyCode) : (k = e.which);
      return (
        (k > 64 && k < 91) ||
        (k > 96 && k < 123) ||
        k == 8 ||
        k == 32 ||
        (k >= 48 && k <= 57)
      );
    }

    function cancelarCompra() {
      stateLocal.showModalCompra = false;
      stateLocal.inicioCompra = true;
    }

    async function confirmaCampanha() {
      ////console.log(stateLocal.participarCampanha);
      if (stateLocal.participarCampanha != true) {
        stateLocal.formasPagamento = store.state.campanhaUsuario[0].paymentMethods;
        let pag = !stateLocal.isEmbedded ? 7 : null;
        if (stateLocal.formasPagamento && stateLocal.formasPagamento.length == 1) {
          pag = stateLocal.formasPagamento[0].idPaymentMethod;
        }
        stateLocal.pagamento = pag;
        stateLocal.terceiro = "";
        stateLocal.cpfTerceiro = "";
      } else {
        getPaymentMethods();
      }

      if (campanhaUsuario.value === false && stateLocal.participarCampanha != true) {
        $("#cpfterceiro").attr("disabled", "disabled");
        $("#nometerceiro").attr("disabled", "disabled");
      } else {
        $("#cpfterceiro").removeAttr("disabled");
        $("#nometerceiro").removeAttr("disabled");
      }
      if (stateLocal.participarCampanha != "") {
        stateLocal.participarCampanha = campanhaUsuario.value;
      }
    }

    const fonteVtex = computed(() => {
      let pluginAtivo = store.state.listConfiguracoes.filter(
        (y) => y.chave == "fonte.vtex" && y.empresaId == store.state.usuario.empresa.id
      );
      if (pluginAtivo.length > 0 && pluginAtivo[0].valor.trim() == "true") {
        stateLocal.validaEntrega = true;
        return false;
      } else {
        stateLocal.validaEntrega = false;
        return true;
      }
    });

    async function buscaEndereco() {
      try {
        stateLocal.isloadingEndereco = true;
        stateLocal.enderecoBusca = [];
        const res = await EnderecoService.ConsultaEndereco(stateLocal.cep);
        stateLocal.enderecoBusca = res.data;
      } catch(e){
        console.log("erro ConsultaEndereco:", e);
        toast.error(tm("msgCarrinho24"));
      }finally{
         stateLocal.isloadingEndereco = false;
      }
    }

    async function buscaEnderecoOK() {
      try {
        stateLocal.isloadingMetodo = true;
        let responseEnd = await ApiBotService.GetMetodosEntrega(
          stateLocal.token,
          stateLocal.idPreOrder,
          0,
          stateLocal.cep
        );
        if (stateLocal.numEndereco !== "") {
          stateLocal.enderecoBusca.viaCep.siafi = stateLocal.numEndereco;
        }
        if (stateLocal.complementoEnd !== "") {
          stateLocal.enderecoBusca.viaCep.complemento = stateLocal.complementoEnd;
        }

        stateLocal.metodosEntrega = responseEnd.data.deliverys;
        stateLocal.retirarLoja = responseEnd.data.withdrawals;
        // Alterar endereco do cliente
        const res = await ApiBotService.PostEnderecoCliente(
            stateLocal.token,
            {
              addressName : stateLocal.enderecoBusca.viaCep.logradouro,
              addressNumber: stateLocal.enderecoBusca.viaCep.siafi,
              complement:  stateLocal.enderecoBusca.viaCep.complemento ,
              district: stateLocal.enderecoBusca.viaCep.bairro,
              state: stateLocal.enderecoBusca.viaCep.uf,
              city: stateLocal.enderecoBusca.viaCep.localidade,
              zipCode:  stateLocal.cep
            });
            stateLocal.enderecoAlterar = stateLocal.enderecoBusca;
            stateLocal.endereco = res.data;
            let endPreferencial = store.state.ath.enderecosClientes[store.state.selectedChatQueue.chatId].find((x) => x.preferentialAddress);
            endPreferencial.preferentialAddress = false;
            store.state.ath.enderecosClientes[store.state.selectedChatQueue.chatId].push(res.data);
            stateLocal.idAddress = res.data.idAddress;
      } catch(e){
        console.log("erro buscaEnderecoOK:", e);
        toast.error(tm("msgCarrinho25"));
       }finally{
        stateLocal.isloadingMetodo = false;
      }
    }

    function tipoMetodo(metodo) {
      stateLocal.metodoEntrega = metodo;
      stateLocal.validaEntrega = false;
      if (metodo.deliveryChannel === "delivery") {
        stateLocal.delivery = true;
      } else {
        stateLocal.delivery = false;
      }
    }

    function limparDados() {
      let pag = !stateLocal.isEmbedded ? 7 : null;
      if (stateLocal.formasPagamento && stateLocal.formasPagamento.length == 1) {
        pag = stateLocal.formasPagamento[0].idPaymentMethod;
      }
      stateLocal.pagamento = pag;
      stateLocal.terceiro = "";
      stateLocal.cpfTerceiro = "";
      stateLocal.nota = false;
      stateLocal.alterarCep = false;
      stateLocal.cep = "";
      stateLocal.enderecoBusca = [];
      stateLocal.numEndereco =  "";
      stateLocal.complementoEnd = "";
      stateLocal.enderecoAlterar = [];
      stateLocal.metodoEntrega= undefined;
      stateLocal.entrega = 0;
      stateLocal.validaEntrega = true;
    }

    const getChats = async () => {
      await store.dispatch("getChats");
    }

    function getDescricaoProduto(produto){
       if (produto.sortimento){
          return produto.desc + " " +
                ( produto.sortimento.productItemName ?  produto.sortimento.productItemName + " ": "") +
                ( produto.sortimento.productSubItemName ?  produto.sortimento.productSubItemName : "");
       }else{
        return produto.desc
       }


    }

    onMounted(async () => {
      //  getLoja()
      getEndereco();
      stateLocal.isLoading = true;
      await confirmaCampanha();
      stateLocal.isLoading = false;
    });

    return {
      stateLocal,
      carrinhoCliente,
      totalCarrinho,
      formataMoeda,
      excluirProduto,
      finalizarPedidoBot,
      finalizarPedidoAth,
      confimarPedidoAth,
      cpfCliente,
      enderecoCliente,
      lojaCliente,
      enderecoLoja,
      cancelarCompra,
      confirmaCampanha,
      fonteVtex,
      tipoMetodo,
      buscaEndereco,
      buscaEnderecoOK,
      alpha,
      nomeCLiente,
      celularCliente,
      formaPagamento,
      Validar,
      idClient,
      campanhaUsuario,
      nomeCampanhaUsuario,
      confirmarTerceiro,
      tm,
      getDescricaoProduto
    };
  },
};
</script>

<style>
#scrollableContent {
  height: 100%;
  margin: 0em;
  overflow-y: auto;
}
</style>
